import React from 'react';
import PropTypes from 'prop-types';
import { TrackLink } from 'app_utils/tracking';
import { makeClassName } from 'utils';

/**
 * Helper tracking link wrapper to simplify components that can be displayed _either_ against a
 * Badge or a BadgeTemplate and should have slightly different tracking parameters as a result.
 *
 * @property {string} [className] - an optional className to add to the top-level element
 * @property {object} track - base tracking parameters
 * @property {string} [target] - optional; if present is used as the link's target attribute (e.g.
 *   to open in a new tab/window); if present, the link is assumed to be an external one
 * @property {string} to - the URL that the link should point to
 * @property {string} track.object_id - the ID of the badge or badge template that this link is
 *   being presented in relation to
 * @property {string} track.object_type - "Badge" or "BadgeTemplate", depending on the type of the
 *   context object
 * @property {string} track.typeSuffix - the portion of the stat type that comes after the object
 *   type in badge and badge template click stats, e.g. if the full stat type is
 *   "badge.additional_information.clicked", this should be "additional_information.clicked"
 */
export const BadgeOrTemplateTrackLink = ({ track, className, to, target, tabIndex, children }) => {
  /**
   * Generates the tracking properties that should be supplied as as the "track" property to the
   * wrapped {@link TrackLink}
   *
   * @returns {object}
   */
  const trackProps = () => {
    const result = { ...track };
    const typeSuffix = result.typeSuffix;
    delete result.typeSuffix;

    if (result.object_type === 'Badge') {
      result.type = `badge.${typeSuffix}`;
    } else {
      result.type = `badge_template.${typeSuffix}`;
    }

    return result;
  };

  return (
    <TrackLink
      className={makeClassName(className)}
      to={to}
      target={target}
      track={trackProps()}
      tabIndex={tabIndex}
    >
      {children}
    </TrackLink>
  );
};

BadgeOrTemplateTrackLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  tabIndex: PropTypes.number,
  to: PropTypes.string.isRequired,
  track: PropTypes.shape({
    object_id: PropTypes.string.isRequired,
    object_type: PropTypes.oneOf(['Badge', 'BadgeTemplate']),
    typeSuffix: PropTypes.string.isRequired,
    snapshot_json: PropTypes.object
  }).isRequired
};

BadgeOrTemplateTrackLink.defaultProps = {
  tabIndex: 0
};

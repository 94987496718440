import React from 'react';
import PropTypes from 'prop-types';
import { TrackLink } from 'app_utils/tracking';

import './key_value_group_evidence.sass';

/**
 * Renders a KeyValueGroupEvidence element.
 *
 * @property {string} name - the name attribute of the key-value group
 * @property {Array<object>} values - the list of key-value pairs associated with the evidence
 * @property {object} tracking - base tracking params to use for stats generated from clicking on
 *   any links that may be associated with the key-value evidence
 */
export const KeyValueGroupEvidence = ({ name, values, tracking }) => {
  /**
   * Render a single KeyValuePairEvidence item, including a link - with appropriate tracking - if
   * the item has a URL.
   *
   * @param {object} kvp - the key-value pair
   * @param {Number} idx - the index of the key-value pair within the list (data associated with
   *   KeyValuePairEvidence is not guaranteed to be unique in the list, so this is necessary to
   *   ensure React is happy)
   * @returns {React.element}
   */
  const renderKeyValuePair = (kvp, idx) => {
    const keyClassName = 'cr-badges-key-value-group-evidence__key';
    let keyContent;

    if (kvp.url) {
      keyContent = (
        <TrackLink
          className={keyClassName}
          to={kvp.url}
          target="_blank"
          track={{
            ...tracking,
            type: 'badge.evidence.clicked',
            url: kvp.url,
            linkLabel: kvp.key
          }}
        >
          {kvp.key}
        </TrackLink>
      );
    } else {
      keyContent = (<div className={keyClassName}>{kvp.key}</div>);
    }

    return (
      <div className="cr-badges-key-value-group-evidence__kv-pair" key={idx}>
        {keyContent}
        <div className="cr-badges-key-value-group-evidence__value">{kvp.value}</div>
      </div>
    );
  };

  return (
    <div className="cr-badges-key-value-group-evidence">
      <div className="cr-badges-key-value-group-evidence__name">{name}</div>
      <div className="cr-badges-key-value-group-evidence__kv-pairs">
        {values.map((kvp, idx) => renderKeyValuePair(kvp, idx))}
      </div>
    </div>
  );
};

KeyValueGroupEvidence.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      url: PropTypes.string
    }).isRequired
  ),
  tracking: PropTypes.object
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeBox } from 'controls/box';
import { withModifierClassNames } from 'controls/modifiers';
import './card.sass';

export const Card = makeBox('Card', 'cr-card');
Card.Visual = withModifierClassNames(
  makeBox('Card.Visual', 'cr-card__visual'),
  'cr-card__visual',
  { size: ['small', 'large'] }
);
Card.Header = makeBox('Card.Header', 'cr-card__header');
Card.Body = makeBox('Card.Body', 'cr-card__body');
Card.Footer = makeBox('Card.Footer', 'cr-card__footer');
Card.Tags = makeBox('Card.Tags', 'cr-card__tags');
Card.Tag = makeBox('Card.Tag', 'cr-card__tag');
Card.Tag.Prefix = makeBox('Card.Tag.Prefix', 'cr-card__tag-prefix', { tagName: 'span' });
Card.Tag.Icon = makeBox('Card.Tag.Icon', 'cr-card__tag-icon', { tagName: FontAwesomeIcon });

export default Card;

// Cover a bunch of mime types and file extensions.
import {faFileImage} from '@fortawesome/pro-light-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/pro-light-svg-icons/faFilePdf';
import {faFileAlt} from '@fortawesome/pro-light-svg-icons/faFileAlt';
import {faFileAudio} from '@fortawesome/pro-light-svg-icons/faFileAudio';
import {faFileVideo} from '@fortawesome/pro-light-svg-icons/faFileVideo';
import {faFileArchive} from '@fortawesome/pro-light-svg-icons/faFileArchive';
import {faFile} from '@fortawesome/pro-light-svg-icons/faFile';
import {faLink} from '@fortawesome/pro-light-svg-icons/faLink';

// Cover a bunch of mime types and file extensions.
const FILE_TYPES = [
  {
    match: /image\/|jpe?g$|gif$|png$|tiff$|svg$/i,
    icon: faFileImage
  },
  {
    match: /pdf$/i,
    icon: faFilePdf
  },
  {
    match:
      /text\/plain|msword|ms-powerpoint|ms-excel|officedocument|docx?$|xlsx?$|pptx?$|txt$|rtf$/i,
    icon: faFileAlt
  },
  {
    match: /audio\/|aac$|ogg$|midi?$|mp3$|mp2$|wav$|wma$|weba$/,
    icon: faFileAudio
  },
  {
    match: /video\/|og[mv]$|avi$|mov$|wmv$|rm$|mp4$|mpe?g$|mpe$|webm$/,
    icon: faFileVideo
  },
  {
    match: /x-stuffit|x-(?:rar|zipx?)-compressed|x-bzip2?|zipx?$|tar$|gz$|rar$|iso$|bz2?$|dmg$|sitx?$/,
    icon: faFileArchive
  }
];


export const FILE = 'file';
export const LINK = 'link';

/**
 * Given a mime type or a filename, make a best guess as to the icon that would represent the file.
 *
 * @param {String} type - A mime-type, extension, filename or url.
 * @param {String} fallback
 * @returns {object}
 */
export const getIconForFile = (type, fallback = FILE) => {
  for (let obj of FILE_TYPES) {
    if (obj.match.test(type)) {
      return obj.icon;
    }
  }

  if (fallback === FILE) {
    return faFile;
  } else {
    return faLink;
  }
};

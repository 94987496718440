import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

/**
 * HOC replaces the given FormattedMessage prop with useIntl generated string. Occasionally
 * a component will need to render the given translation jsx element directly in html attributes
 * which results in the stringified "[Object, object]" instead of the expected translation string.
 * This HOC hackingly reads the props of the given FormattedMessage and then passes them to
 * useIntl (and should only be used to when forcing a string is required).
 * @example
 * const Img = withIntlProps(SizedImage, ['alt'])
 * <Img
 *  src="<url>"
 *  alt={
 *    <FormattedMessage
 *      id="<id>"
 *      defaultMessage="<message>"
 *    />
 *  }
 * />
 *
 * @param {Function} Cpnt the component with props needing translation
 * @param {Array} propsToTranslate an array of prop names strings to be translated.
 * @returns Function
 */

export const withIntlProps = (Cpnt, propsToTranslate) => {
  const NewCpnt = (props) => {
    const intl = useIntl();
    const propValues = propsToTranslate.map((name) => props[name]);
    const translations = React.useMemo(() => {
      const translatedProps = {};
      propsToTranslate.forEach((propToTranslate) => {
        if (typeof props[propToTranslate] === 'object') {
          const el = props[propToTranslate].props;
          if (el?.id && el?.defaultMessage) {
            translatedProps[propToTranslate] =
              intl.formatMessage({
                id: el.id,
                defaultMessage: el.defaultMessage
              }, el?.values);
            return;
          }
        }

        translatedProps[propToTranslate] = (
          props[propToTranslate]
        );
      });
      return translatedProps;
    }, [propsToTranslate, ...propValues]);

    return (
      <Cpnt
        {...props}
        {...translations}
      />
    );
  };

  const propTypes = {};
  propsToTranslate.forEach((name) => {
    propTypes[name] = PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]);
  });

  NewCpnt.displayName = Cpnt.displayName;
  NewCpnt.propTypes = {
    ...Cpnt.propTypes,
    ...propTypes
  };

  return NewCpnt;
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isExpired } from 'badges';
import { makeClassName } from 'utils';
import { FormattedMessage } from "react-intl";

import './expired_badge_label.sass';

export function ExpiredBadgeLabel(props) {
  if (isExpired(props.expiresAt)) {
    return (
      <div className={makeClassName('expired-badge-label', props.className)}>
        <FormattedMessage
          id="dashboard.expired_label"
          defaultMessage="Expired"
        />
      </div>
    );
  }
  return <Fragment />;
}


ExpiredBadgeLabel.propTypes = {
  expiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { RoleButton, propTypes as roleButtonPropTypes } from 'aria/role_button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeClassName } from 'utils';
import './tag_icon.sass';

/**
 * Basic presentation component for an icon within a Tag.
 *
 * @param {object} props
 * @param {object} props.icon - FontAwesome icon that should be displayed
 * @returns {JSX.Element}
 * @constructor
 */
export const TagIcon = (props) => {
  return (
    <div className="cr-tag__icon">
      <FontAwesomeIcon icon={props.icon}/>
    </div>
  );
};

TagIcon.propTypes = {
  icon: PropTypes.object.isRequired
};

/**
 * A clickable icon button component.
 *
 * To be used when *only* the icon area in the tag is clickable.
 * If the whole tag is clickable, wrap the tag and use a regular {@link TagIcon} instead.
 *
 * In addition to the required props, accepts any props defined by {@link RoleButton}.
 *
 * @param {object} props
 * @param {object} props.icon - FontAwesome icon that should be displayed
 * @param {object} props.onClick - Handler called when the icon is clicked
 * @param {string} props.aria-label - ARIA label describing the purpose of the button
 * @returns {JSX.Element}
 * @constructor
 */
export const TagIconButton = ({ icon, onClick, className, ...props }) => {
  const fullClass = makeClassName(className, "cr-tag__icon", "cr-tag__icon--clickable");
  return (
    <RoleButton className={fullClass} onClick={onClick} tagName="div" {...props}>
      <FontAwesomeIcon icon={icon}/>
    </RoleButton>
  );
};

TagIconButton.propTypes = {
  ...roleButtonPropTypes,
  "aria-label": PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

import React from 'react';
import PropTypes from 'prop-types';

import './id_evidence.sass';

/**
 * Render a IdEvidence item, which consists of a title and description.
 *
 * @property {string} description - the description of the evidence
 * @property {string} title - the title of the evidence
 */
export const IdEvidence = props => {
  return (
    <div>
      <div>{props.title}</div>
      <div className="cr-badges-id-text-evidence__desc">{props.description}</div>
    </div>
  );
};

IdEvidence.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

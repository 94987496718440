import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'controls/placeholder';
import { makeClassName, uniqueId } from 'utils';
import { withWidthBreakpointsProp } from 'utils/with_width_breakpoints_prop';
import './text.sass';

/**
 * Text component applies standard Credly (non-heading) typography styles and
 * handles various text states (loading and empty).
 *
 * @param {Object} props
 *  @param {Boolean} props.loadng is the text loading
 *  @param {Object} loadingSettings
 *    @param {Number} loadingSettings.lineCount the number of placeholder text lines to show when loading
 *    @param {String} loadingSettings.tagName the tagName used to wrap placeholders, defaults to props.tagName if not set
 *  @param {String} props.size the size modifier.
 *  @param {String} props.className custom class name
 *  @param {String} props.emptyValue defaults to '--', the value that appears when text children value is empty.
 *  @param {*} props.tagName tagName used to wrap text element, defaults to 'span'
 *  @param {String} props.appearance appearance modifier appending a custom className ex (cr-text--{appearance})
 *  @param {Boolean} props.nowrap if true, appends css class that hides overflow text after line one.
 *  @param {Boolean} props.block if true, appends css class that applies "display: block" to text wrapper
 * @returns {JsxElement}
 */

const Text = ({
  loading,
  loadingSettings,
  children,
  size,
  className,
  emptyValue,
  tagName,
  appearance,
  nowrap,
  display,
  ...otherProps
}) => {
  const settings = loadingSettings || {};
  if (loading) {
    const loadingClassName = makeClassName(
      settings?.className,
      appearance && `cr-text-placeholder--${appearance}`
    );
    return (
      <TextPlaceholder
        {...settings}
        tagName={settings.tagName || tagName}
        className={loadingClassName}
        size={size}
      />
    );
  }

  const TagName = tagName || 'span';
  const textClassName = makeClassName(
    'cr-text',
    className,
    size && `cr-text--size-${size}`,
    appearance && `cr-text--${appearance}`,
    nowrap && 'cr-text--nowrap',
    display && `cr-text--display-${display}`
  );
  return (
    <TagName
      {...otherProps}
      className={textClassName}
    >
      {children || emptyValue}
    </TagName>
  );
};

Text.propTypes = {
  size: PropTypes.any,
  className: PropTypes.string,
  display: PropTypes.oneOf(['block', 'inline']),
  appearance: PropTypes.string,
  tagName: PropTypes.string,
  emptyValue: PropTypes.any,
  children: PropTypes.any,
  loading: PropTypes.bool,
  nowrap: PropTypes.bool,
  loadingSettings: PropTypes.shape({
    lineCount: PropTypes.number,
    className: PropTypes.string,
    tagName: PropTypes.any
  })
};

Text.defaultProps = {
  emptyValue: '--'
};

/**
 * TextPlaceholder component displays placeholders that resemble text lines and paragraphs.
 *
 * @param {Object} props
 *  @param {Number} props.lineCount the number of placeholder text lines to show when loading
 *  @param {String} props.tagName the tagName used to wrap placeholders, defaults to 'div' if not set
 *  @param {String} props.size the size modifier.
 * @returns {JsxElement}
 */

export const TextPlaceholder = ({ lineCount, className, size, tagName, ...otherProps }) => {
  let i = 0;
  const keyPrefix = React.useMemo(() => uniqueId(), []);
  const [placeholders, setPlaceholders] = React.useState([]);
  React.useEffect(() => {
    lineCount = lineCount || 1;
    if (placeholders.length !== lineCount) {
      const list = [];
      while (i++ < lineCount) {
        const placeholderClassName = makeClassName(
          'cr-text-placeholder__line',
          `cr-text-placeholder__line--${i}`,
          size && `cr-text-placeholder__line--size-${size}`
        );

        list.push(
          <Placeholder
            key={`${keyPrefix}-${i}`}
            className={placeholderClassName}
          />
        );
      }
      setPlaceholders(list);
    }
  }, [lineCount, size]);
  const TagName = tagName || 'div';
  const containerClassName = makeClassName(
    'cr-text-placeholder',
    className,
    `cr-text-placeholder--size-${size}`
  );
  return (
    <TagName
      {...otherProps}
      className={containerClassName}
    >
      {placeholders}
    </TagName>
  );
};

TextPlaceholder.propTypes = {
  size: PropTypes.any,
  className: PropTypes.string,
  lineCount: PropTypes.number,
  tagName: PropTypes.string
};

Text.defaultProps = {
  emptyValue: '--'
};

const TextWithBreakpoints = withWidthBreakpointsProp(Text, 'size');

export { TextWithBreakpoints as Text };

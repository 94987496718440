import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

import './placeholder.sass';

export const Placeholder = (props) => {
  return <div className={makeClassName('cr-placeholder', props.className)}/>;
};

Placeholder.propTypes = {
  className: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { useWidthBreakpoints } from './use_width_breakpoints';

/**
 * HOC that updates the given component's prop value based on the value assigned
 * to the window width.
 * @example
 * const MyComponent = withWidthBreakpointsProp(Comp, 'size', 'mini');
 * <MyComponent
 *  size="initialSize"
 *  sizeBreakpoints={{
 *    xs: 'mini',
 *    sm: 'lil',
 *    lg: 'big',
 *    xl: 'biggest',
 *    default: 'newDefault'
 *  }}
 * />
 * 
 * // if no breakpoint setting is available, `sizeBreakpoint.default` is used, making the value
 * // of the `size` prop `"newDefault"`. If `sizeBreakpoint.default` is not set, then the value of
 * // the `size` prop will be used: `"initialSize"`
 * 
 * @param {Function} Cpnt the component to extend
 * @param {*} propName the propName being updated when the window width changes
 * @param {*} defaultValue the default value to use if the breakpoint setting and prop value is undefined
 */
export const withWidthBreakpointsProp = (Cpnt, propName, defaultValue) => {
  const settingsPropName = `${propName}Breakpoints`;
  const NewCpnt = (props) => {
    const view = useWidthBreakpoints();
    const { [settingsPropName]: settings, ...otherProps } = props;
    const value = React.useMemo(() => (
      (view && settings && settings[view]) ||
      settings?.default ||
      otherProps[propName] ||
      defaultValue
    ), [view, settings]);
    return (<Cpnt {...{ ...otherProps, [propName]: value }} />);
  };
  NewCpnt.displayName = Cpnt.displayName;
  NewCpnt.propTypes = {
    ...Cpnt.propTypes,
    [settingsPropName]: PropTypes.shape({
      default: PropTypes.any,
      xs: PropTypes.any,
      sm: PropTypes.any,
      md: PropTypes.any,
      lg: PropTypes.any,
      xl: PropTypes.any
    })
  };
  return NewCpnt;
};

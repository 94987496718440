import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { makeClassName, Routes } from 'utils';
import { SmartLink } from 'controls/smart_link';
import { FormattedMessage } from 'react-intl';

import './badge_skills.sass';

/**
 * Render the skill tags associated with a badge template.
 *
 * @property {string} [className] - an additional className to add to the top-level element (the
 *   list of skill tags, rather than individual items)
 * @property {boolean} showLinks - whether to link the skill tags to their respective LMI pages;
 *   this will typically come from BadgeTemplate#show_skill_tag_links
 * @property {Array<object>} skills - an array of skill objects; should have a least the skill
 *   ID, name, and vanity_slug
 * @property {object} tracking - object containing stat-tracking extras
 * @property {boolean} hideTitle - hide the heading
 * @property {object} [tracking.snapshot_json] - additional data to send along with stats generated
 *   by clicking on the skill tags; e.g. info about the badge or badge template that the skills are
 *   presented on
 */
export const BadgeSkills = ({ className, showLinks, tracking, hideTitle, skills }) => {
  /**
   * Returns a full className for the top-level element.
   *
   * @returns {string}
   */
  const buildClassName = () => makeClassName('cr-badges-badge-skills', className);

  /**
   * Renders an individual skill tag, taking into account whether to link it to LMI and, if so,
   * including tracking parameters.
   *
   * @param {object} skill - the skill to render
   * @param {number} index - Used for Pendo tracking, added in [CRED-1475]
   * @returns {JSX.Element}
   */
  const renderSkill = (skill, index) => {
    const className = ['cr-badges-badge-skills__skill', `cr-badges-badge-skills__${index}`];
    let inner = skill.name;

    if (showLinks) {
      className.push('cr-badges-badge-skills__skill--linked');
      const linkProps = {
        action: Routes.skillsInsightsUrl(skill.vanity_slug)
      };

      if (tracking) {
        linkProps.trackingParams = {
          ...tracking,
          object_id: skill.id,
          object_type: 'Skill',
          type: 'skill.tag.clicked'
        };
      }
      inner = <SmartLink {...linkProps}>{inner}</SmartLink>;
    }

    return <li key={skill.id} className={makeClassName(className)}>{inner}</li>;
  };

  if (!skills || skills.length === 0) { return null; }

  return (
    <div className={buildClassName()}>
      {!hideTitle && (
        <Heading appearance="settings-head">
          <FormattedMessage
            id="badge.view.skills"
            defaultMessage="Skills"
          />
        </Heading>)}
      <ul className="cr-badges-badge-skills__skills">
        {skills.map((skill, index) => renderSkill(skill, index))}
      </ul>
    </div>
  );
};

BadgeSkills.propTypes = {
  className: PropTypes.string,
  showLinks: PropTypes.bool,
  hideTitle: PropTypes.bool,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      vanity_slug: PropTypes.string
    })
  ).isRequired,
  tracking: PropTypes.shape({ snapshot_json: PropTypes.object })
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { TrackLink } from 'app_utils/tracking';
import { Heading } from 'controls/heading';
import { SizedImage } from 'controls/sized_image';

import './endorsement.sass';

/**
 * Component for displaying a single Endorsement item, which can represent either an actual, top-
 * level Endorsement or one of its Credits.
 *
 * @property {string} [className] - an optional class name to add to the top-level element
 * @property {string} description - the description of the endorsement or credit recommendation
 * @property {object} endorser - an object with info about the endorser Organization
 *   @property {string} endorser.name - name of the endorser
 *   @property {string} endorser.image_url - a URL to the endorser's logo
 * @property {object} tracking - an object containing parameters used for tracking clicks on the
 *   endorser's name
 * @property {string} [url] - URL that user should be taken to when clicking on the endorser's name
 */
export const Endorsement = ({ className, description, endorser, tracking, url, id }) => {
  /**
   * Renders the endorser name as a heading, possibly wrapped in a link, if the Endorsement has a
   * URL
   *
   * @returns {React.element}
   */
  const renderEndorserName = () => {
    const innerContent = (
      <Heading appearance="custom" className="cr-badges-endorsement__endorser-name">
        {endorser.name}
      </Heading>
    );
    if (url) {
      return (
        <TrackLink
          to={url}
          target="_blank"
          track={trackProps()}
          className="cr-badges-endorsement__endorser-name-link"
        >
          {innerContent}
        </TrackLink>
      );
    } else {
      return innerContent;
    }
  };

  /**
   * Gets the stat properties used to track clicks on the endorsement.
   *
   * @returns {object}
   */
  const trackProps = () => ({
    ...tracking,
    type: 'endorsement.click',
    object_id: id,
    object_type: 'Endorsements::Endorsement'
  });

  return (
    <li className={makeClassName('cr-badges-endorsement', className)}>
      <SizedImage
        src={endorser.image_url}
        alt={endorser.name}
        width={64}
        className="cr-badges-endorsement__endorser-img"
      />
      <div className="cr-badges-endorsement__text">
        {renderEndorserName()}
        <div className="cr-badges-endorsement__description">
          {description}
        </div>
      </div>
    </li>
  );
};

Endorsement.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  endorser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string
  }).isRequired,
  tracking: PropTypes.object,
  url: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ConditionalWrapper wraps its children with the given tagName element when active is true.
 * Otherwise, it returns the content unwrapped.
 * @example
 * <ConditionalWrapper
 *  tagName={SmartLink}
 *  props={{ ...linkProps> }}
 *  active={!isLinkDisabled}
 * >
 *   <span className="the-content">
 *    This content always shows, but it may or may not
 *    be wrapped with a SmartLink.
 *   </span>
 * </ConditionalWrapper>
 *
 * @param {Object} props
 *  @param {*} props.tagName component tagName conditionally wrapping the content.
 *  @param {Object} props.props the props for given tagName element
 *  @param {Boolean} props.active if true, the content gets wrapped with given tagName element
 *  @param {*} props.children the contents being rendered
 * @returns JsxElement
 */

export const ConditionalWrapper = ({ tagName, props, active, children }) => {
  if (!active) return children;

  const TagName = tagName;
  return (
    <TagName {...props}>
      {children}
    </TagName>
  );
};

ConditionalWrapper.propTypes = {
  tagName: PropTypes.any.isRequired,
  props: PropTypes.object,
  active: PropTypes.bool,
  children: PropTypes.any
};

import React from 'react';
import { useIntl } from 'react-intl';
import { makeClassName } from 'utils';
import { SmartLink } from 'controls/smart_link';
import { Card } from 'controls/card';
import { Text } from "controls/text";
import { Visual } from "controls/visual";
import { ConditionalWrapper } from 'controls/conditional_wrapper';
import BadgePlaceholderImage from 'images/badge-placeholder.png';
import PropTypes from 'prop-types';
import './org_card.sass';

export const ORG_CARD_IMAGE_WIDTH = 120;

const orgLinkProps = (owner) => ({
  action: owner.vanity_url,
  target: '_self',
  trackingParams: {
    object_id: owner.id,
    object_type: 'Organization',
    type: 'organization.clicked',
    snapshot_json: {
      organization_name: owner.name,
      organization_id: owner.id,
      href: window.location.href
    }
  }
});

const OrgCardContext = React.createContext();

export const OrgCard = ({ className, org, loading, tabIndex = 0, showBadgeCount }) => {
  return (
    <Card className={makeClassName('org-card', className)}>
      <OrgCardContext.Provider value={{ org, loading, tabIndex }}>
        <OrgCard.Visual />
        <Card.Body className="org-card__body">
          <OrgCard.Title/>
          {showBadgeCount && <OrgCard.BadgeCount badgeCount={org?.badge_count}/>}
        </Card.Body>
      </OrgCardContext.Provider>
    </Card>
  );
};

OrgCard.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vanity_url: PropTypes.string.isRequired,
    badge_count: PropTypes.number,
    photo_url: PropTypes.string
  }),
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  showBadgeCount: PropTypes.bool
};

const OrgCardTitle = ({ className, ...otherProps }) => {
  const contextValue = React.useContext(OrgCardContext);
  const { org, loading, tabIndex } = {
    ...contextValue,
    ...otherProps
  };

  return (
    <ConditionalWrapper
      tagName={SmartLink}
      props={org && { ...orgLinkProps(org), className: 'org-card__title-link' }}
      active={!!(tabIndex !== -1 && !loading && org?.name)}
    >
      <Text
        loading={loading}
        loadingSettings={{
          className: 'org-card__title-placeholder'
        }}
        appearance="title"
        size={2}
        display="block"
        nowrap
        className={
          makeClassName('org-card__title', className)
        }
      >
        {org?.name}
      </Text>
    </ConditionalWrapper>
  );
};

OrgCardTitle.displayName = 'OrgCard.Title';
OrgCardTitle.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string
  }),
  loading: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  showBadgeCount: PropTypes.bool
};

OrgCard.Title = OrgCardTitle;

const OrgCardBadgeCount = ({ badgeCount }) => {
  const intl = useIntl();

  return (
    <div className="org-card__badge-count">
      <Text appearance="title">{badgeCount}</Text>
      <Text>
        {intl.formatMessage({
          id: 'org_card.badge_label',
          defaultMessage: '{itemCount, plural, =0 {Badges} one {Badge} other {Badges}}'
        }, { itemCount: badgeCount })}
      </Text>
    </div>
  );
};

OrgCardBadgeCount.displayName = 'OrgCard.BadgeCount';
OrgCardBadgeCount.propTypes = {
  badgeCount: PropTypes.number
};

OrgCard.BadgeCount = OrgCardBadgeCount;

const OrgCardVisual = ({ className, ...otherProps }) => {
  const contextValue = React.useContext(OrgCardContext);
  const { org, loading, tabIndex = 0 } = {
    ...contextValue,
    ...otherProps
  };

  return (
    <ConditionalWrapper
      tagName={SmartLink}
      props={org && { ...orgLinkProps(org), className: 'org-card__visual-link' }}
      active={!!(tabIndex !== -1 && !loading && org?.name)}
    >
      <Card.Visual
        size="small"
        {...otherProps}
        className={makeClassName('org-card__visual', className)}
      >
        <Visual
          loading={loading}
          value={org?.photo_url}
          emptyValue={BadgePlaceholderImage}
          width={ORG_CARD_IMAGE_WIDTH}
          height={ORG_CARD_IMAGE_WIDTH}
          className="org-card__image"
          alt={org?.name}
          shape="badge"
        />
      </Card.Visual>
    </ConditionalWrapper>
  );
};

OrgCardVisual.displayName = 'OrgCard.Visual';
OrgCardVisual.propTypes = {
  org: PropTypes.shape({
    photo_url: PropTypes.string
  }),
  loading: PropTypes.bool,
  className: PropTypes.string
};

OrgCard.Visual = OrgCardVisual;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from "translations";
import { Tag, TagList } from "controls/tags";
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup';
import { faFileSignature } from '@fortawesome/pro-regular-svg-icons/faFileSignature';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './template_attributes.sass';

/**
 * Render (up to) all four template attributes (cost, level, time to earn, and "type category") as a
 * list. Or render nothing if none of the attributes has a value.
 *
 * @property {string} [appearance=normal] - the appearance to use when presenting the attributes,
 *   can be "normal" for a stacked presentation with horizontal bars between elements or "compact"
 *   for a more compact 2x2 representation, suitable for mobile displays
 * @property {string} [className] - an optional, extra className to apply to the top-level element
 *   containing the attributes
 * @property {string} cost - the cost attribute; see badge_template.rb for valid options
 * @property {string} level - the level attribute; see badge_template.rb for valid options
 * @property {string} timeToEarn - the time_to_earn attribute; see badge_template.rb for valid
 *   options
 * @property {string} typeCategory - the type_category attribute; see badge_template.rb for valid
 *   options
 */
const TemplateAttributes = memo(({ appearance = 'normal', className, cost, level, timeToEarn, typeCategory }) => {
  /**
   * Generate the modifier className to use for the top-level component, based on the desired
   * appearance.
   *
   * @returns {string}
   */
  const appearanceClassName = () => {
    if (appearance === 'compact') {
      return 'cr-badges-template-attributes--compact';
    } else {
      return 'cr-badges-template-attributes--normal';
    }
  };

  /**
   * Render a single template attribute element.
   *
   * @param {string} label - the attribute label, i.e. Cost, Level, Time, Type
   * @param {string} value - the attribute value
   * @returns {React.element}
   */
  const renderItem = (label, value, icon) => {
    if (value && value !== 'N/A') {
      const itemIcon = <FontAwesomeIcon className="cr-badges-template-attributes-tag-list--tag-icon" icon={icon} />;

      return (
        <Tag className="cr-badges-template-attributes-tag-list--tag" key={label}>
          {itemIcon}
          <span className="cr-badges-template-attributes__value">
            <FormattedMessage
              id={intlKeyFromValue(value, "badge." + label)}
              defaultMessage={value}
            />
          </span>
        </Tag>
      );
    }
  };

  const items = [
    renderItem('Type', typeCategory, faFileSignature),
    renderItem('Level', level, faLayerGroup),
    renderItem('Time', timeToEarn, faClock),
    renderItem('Cost', cost, faDollarSign)
  ].filter(el => el);

  if (items.length > 0) {
    const builtClassName = makeClassName(
      'cr-badges-template-attributes',
      appearanceClassName(),
      className
    );

    return (
      <div className={builtClassName}>
        <TagList>
          {items}
        </TagList>
      </div>
    );
  } else {
    return null;
  }
});

TemplateAttributes.propTypes = {
  appearance: PropTypes.oneOf(['compact', 'normal']),
  className: PropTypes.string,
  cost: PropTypes.string,
  level: PropTypes.string,
  timeToEarn: PropTypes.string,
  typeCategory: PropTypes.string
};

TemplateAttributes.displayName = 'TemplateAttributes';
export { TemplateAttributes };

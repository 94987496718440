import React from 'react';
import { Tag, TagList } from 'controls/tags';

import './tags_loading.sass';

/**
 * UI component for the "loading" state of a list of tags.
 *
 * Displays a placeholder with a loading animation.
 *
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
export const TagsLoading = (_props) => {
  // This sequence was selected manually with some fiddling to try to
  // minimize ugly breaks and avoid lining up ends of tags on adjacent lines,
  // since that is visually distracting.
  const tagWidths = [10, 6, 7, 5, 12, 8, 6, 7, 9, 5, 10];

  return (
    <TagList className="cr-tags-loading">
      {tagWidths.map((tagWidth, idx) => {
        return (
          <Tag
            ui="default"
            className={`cr-tags-loading__tag cr-tags-loading__tag--size-${tagWidth}`}
            key={idx}
            children=""
          />
        );
      })}
    </TagList>
  );
};

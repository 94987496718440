import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import './tag.sass';

/**
 * Allowed values for Tag's ui prop.
 *
 * @type {string[]}
 */
export const TAG_UIS = ['default', 'skill', 'skill-bold', 'smallbold', 'standard', 'heading', 'skill-public', 'alert'];

/**
 * Basic tag component.
 *
 * Intentionally kept fairly simple. Handles the presentation of tags in their different forms.
 *
 * @param {object} props
 * @param {String} [props.ui=standard] - The UI style. One of:
 *   - standard: New design system standard.
 *   - skill: Skill tag display with green color scheme.
 *   - skill-public: Skill tag as displayed on public and earner views of badges and badge templates
 *   - heading: Small green tag for use in Headings
 *   - skill-bold: Skill tag display with green color scheme and bold font.
 *   - smallbold: Smaller font, bold text.
 *   - alert: Small orange tag used for alerts or better visibility
 *   - default: Old default style, deprecated.
 * @param {String} [props.className] - CSS class name to apply to the component.
 * @param {boolean} [props.clickable] - Present the tag as a clickable object. Actual click handling
 *                                      should be handled by a wrapping component.
 * @param {boolean} [props.highlighted] - Highlight the tag, to identify it as part of a selection or other active group.
 * @param {boolean} [props.focused] - Visually identify the tag as having focus.
 * @param {JSX.Element} [props.icon] - Element to use as inline iconography in the tag.
 *  Can be a clickable component if desired. To take advantage of default styling, either use the
 *  accompanying {@link TagIcon} component or use the +cr-tag-icon-defaults mixin to style a custom
 *  icon.
 * @returns {JSX.Element}
 *
 * @example <caption>Passing an icon wrapped in a clickable component.</caption>
 * <Tag
 *   icon={
 *     <RoleButton onClick={removeTag}>
 *       <FontAwesomeIcon icon={faTimes}/>
 *     </RoleButton>
 *   }
 * >
 *   Tag with close button
 * </Tag>
 *
 * @example <caption>Passing the clickable prop and wrapping the tag in a component to handle clicks on the whole element.</caption>
 * <Link to="/widgets">
 *   <Tag clickable>Widgets</Tag>
 * </Link>
 */
export const Tag = (props) => {
  const { clickable, focused, highlighted, ui } = props;

  const tagClass = makeClassName(
    'cr-tag',
    `cr-tag--${ui}`,
    clickable && 'cr-tag--clickable',
    focused && 'cr-tag--focused',
    highlighted && 'cr-tag--highlighted',
    props.className
  );

  return (
    <div className={tagClass}>
      {props.leftImageSrc && <img src={props.leftImageSrc} className="cr-tag__left-image" />}
      <div className="cr-tag__inner">
        <span className={`cr-tag-text--${ui}`}>{props.children}</span>
      </div>
      {props.icon}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  focused: PropTypes.bool,
  highlighted: PropTypes.bool,
  icon: PropTypes.node,
  leftImageSrc: PropTypes.string,
  ui: PropTypes.oneOf(TAG_UIS)
};

Tag.defaultProps = {
  ui: 'standard'
};

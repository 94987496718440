import { getCurrentUser } from "../api/users";
import { useQuery } from "@tanstack/react-query";

export const useCurrentUser = () => {
  return useQuery({
    queryKey: ["currentUser"],
    queryFn: () => getCurrentUser(),
    staleTime: Infinity,
    initialData: () => {
      const currentUser = window.initialAppValues.currentUser;
      return currentUser && !currentUser.anonymous ? currentUser : null;
    }
  });
};

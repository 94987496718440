import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { BadgeOrTemplateTrackLink } from './badge_or_template_track_link';
import { SmartLink } from 'controls/smart_link';
import Icon from 'controls/icon';
import { BADGE_TEMPLATE_ACTIVITY_TYPES, iconFor } from './badge_template_activity_types.js.erb';

import './earning_criterion.sass';

/**
 * Render an individual criterion (BadgeTemplateActivity) required to earn a badge.
 *
 * @property {string} activity_type - one of the activity types permitted by BadgeTemplateActivity
 * @property {string} [required_badge_template_id] - if activity_type is "Badge", then this should
 *   be the ID of the referenced badge template
 * @property {string} title - the title to display for the criterion (in case of a "Badge" criterion
 *   this would be the badge template name)
 * @property {string} [url] - the URL of the criterion (including vanity URL to badge template in
 *   case of a "Badge"-type criterion)
 */
export const EarningCriterion = memo(({
  activity_type: activityType,
  required_badge_template_id: requiredBadgeTemplateId,
  title,
  tracking,
  url
}) => {
  const renderIcon = () => (
    <Icon
      className="cr-badges-earning-criterion__icon"
      image={iconFor(activityType)}
      title={activityType}
    />
  );

  const linkTargetAndUrl = () => {
    let target = '_blank';
    let linkUrl = url;
    if (activityType === 'Badge') {
      target = null;
      linkUrl = url.replace(document.location.origin, '');
    }
    return { target, linkUrl };
  };

  const renderLink = () => {
    const { target, linkUrl } = linkTargetAndUrl();
    if (tracking) {
      return renderTrackLink(target, linkUrl);
    } else {
      return (
        <SmartLink className="cr-badges-earning-criterion__title" target={target} action={linkUrl}>
          {title}
        </SmartLink>
      );
    }
  };

  const renderTrackLink = (target, linkUrl) => {
    const snapshotJSON = tracking ? tracking.snapshot_json : {};
    return (
      <BadgeOrTemplateTrackLink
        className="cr-badges-earning-criterion__title"
        to={linkUrl}
        target={target}
        track={{
          ...tracking,
          typeSuffix: 'criteria_urls.clicked',
          snapshot_json: { ...snapshotJSON, ...trackingSnapshot() }
        }}
      >
        {title}
      </BadgeOrTemplateTrackLink>
    );
  };

  const trackingSnapshot = () => {
    const result = { type: activityType };
    if (activityType === 'Badge') {
      result.template_id = requiredBadgeTemplateId;
      result.template_name = title;
    } else {
      result.title = title;
      result.url = url;
    }
    return result;
  };

  const classNames = [
    'cr-badges-earning-criterion',
    url && 'cr-badges-earning-criterion--actionable'
  ];

  return (
    <div className={makeClassName(classNames)}>
      {renderIcon()}
      {url ? renderLink() : <div className="cr-badges-earning-criterion__title">{title}</div>}
    </div>
  );
});

EarningCriterion.displayName = 'EarningCriterion';

EarningCriterion.propTypes = {
  activity_type: PropTypes.oneOf(BADGE_TEMPLATE_ACTIVITY_TYPES).isRequired,
  required_badge_template_id: PropTypes.string,
  title: PropTypes.string.isRequired,
  tracking: PropTypes.object,
  url: PropTypes.string
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { isIE } from 'utils/browser';
import { SizedImage } from 'controls/sized_image';
import './avatar.sass';


/**
 * A circular avatar, for a user or organization.
 */
export class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgAspect: 'auto',
      smallImage: false
    };
    this.m_mounted = false;
  }

  /**
   * Update aspect on mount.
   */
  componentDidMount() {
    this.m_mounted = true;
    this.updateImageAspect();
  }

  /**
   * Update aspect on update, if the image changed.
   */
  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.updateImageAspect();
    }
  }

  /**
   * Clean up.
   */
  componentWillUnmount() {
    this.m_mounted = false;
  }

  /**
   * Update the aspect, so the image will display correctly.
   */
  updateImageAspect() {
    const url = this.props.src;
    if (url) {
      const img = new Image();
      img.onload = () => {
        if (this.m_mounted) {
          if (isIE()) {
            const aspect = img.height > img.width ? 'portrait' : 'landscape';
            if (aspect !== this.state.imgAspect) {
              this.setState({ imgAspect: aspect });
            }
          }

          if (img.height < this.props.autoBelowWidth || img.width < this.props.autoBelowWidth) {
            this.setState({ smallImage: true });
          }
        }
      };
      img.src = url;
    }
  }

  /**
   * Renders the component.
   *
   * @returns {React.element}
   */
  render() {
    let imgClass;
    if (this.state.imgAspect === 'auto') {
      imgClass = 'cr-avatar__image--auto';
    } else if (this.state.imgAspect === 'portrait') {
      imgClass = 'cr-avatar__image--portrait';
    } else {
      imgClass = 'cr-avatar__image--landscape';
    }

    let img;
    if (this.props.src) {
      img = (
        <SizedImage
          alt={this.props.alt}
          src={this.props.src}
          className={imgClass}
          width={this.props.width}
          style={{ maxWidth: this.props.width }}
        />
      );
    } else {
      img = this.props.emptyImage;
    }

    return (
      <div
        className={makeClassName(
          'cr-avatar__image-wrap',
          this.state.smallImage && 'cr-avatar__image--small',
          this.props.className
        )}
      >
        {img}
      </div>
    );
  }
}

Avatar.propTypes = {
  src: PropTypes.string,
  emptyImage: PropTypes.node,
  alt: PropTypes.string,
  autoBelowWidth: PropTypes.number,
  className: PropTypes.string,
  width: PropTypes.number
};

Avatar.defaultProps = {
  autoBelowWidth: -1
};

import React from 'react';
import PropTypes from 'prop-types';
import { StandardGridItemContent } from 'controls/standard_grid_item_content';
import { makeClassName } from 'utils';
import BadgePlaceholderImage from 'images/badge-placeholder.png';

export function Medium(props) {
  // Forward any alt prop received to the underlying component as-is, so that consumers of this
  // component can specify an empty alt prop in the same way as StandardGridItemContent consumers
  const altProp = {};
  if (props.hasOwnProperty('alt')) {
    altProp.alt = props.alt;
  }
  return (
    <StandardGridItemContent
      {...altProp}
      className={makeClassName(['c-badge', 'c-badge--medium', props.className])}
      image_url={props.image_url || BadgePlaceholderImage}
      title={props.name}
      subtitle={props.issuer_name}
    />
  );
}

Medium.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image_url: PropTypes.string.isRequired,
  issuer_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { TrackLink } from 'app_utils/tracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from 'utils/icons';

import './url_evidence.sass';

/**
 * Render an item of UrlEvidence, including appropriate tracking parameters to generate stats when
 * the link is clicked.
 *
 * @property {string} [description] - the description associated with the evidence
 * @property {string} name - the name associated with the evidence (used as link label)
 * @property {object} tracking - the base tracking parameters used to generate stats when the link
 *   is clicked
 * @property {string} value - the URL to which the evidence links
 */
export const UrlEvidence = ({ description, name, tracking, value, external_file }) => {
  /**
   * Find the appropriate icon for the file type.
   *
   * @returns {string}
   */
  const getIcon = () => {
    const type = external_file ? external_file.mime_type : value;
    return icons.getIconForFile(type, external_file ? icons.FILE : icons.LINK);
  };

  /**
   * Get the tracking object.
   *
   * @returns {object}
   */
  const getTracking = () => {
    const badgeTracking = {
      ...tracking,
      type: 'badge.evidence.clicked',
      linkLabel: name
    };

    if (external_file) {
      return {
        ...badgeTracking,
        handle: external_file.handle,
        downloadable: true
      };
    } else {
      return {
        ...badgeTracking,
        url: value
      };
    }
  };

  return (
    <div className="cr-badges-url-evidence">
      <div className="cr-badges-url-evidence__icon">
        <FontAwesomeIcon icon={getIcon()}/>
      </div>
      <div className="cr-badges-url-evidence__content">
        <TrackLink
          className="cr-badges-url-evidence__link"
          to={value}
          target="_blank"
          track={getTracking()}
        >
          {name}
        </TrackLink>
        {
          description &&
            <div className="cr-badges-url-evidence__desc">{description}</div>
        }
      </div>
    </div>
  );
};

UrlEvidence.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  tracking: PropTypes.object,
  value: PropTypes.string.isRequired,
  external_file: PropTypes.shape({
    mime_type: PropTypes.string,
    handle: PropTypes.string
  })
};

import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons/faEllipsisHAlt';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faIdCardAlt } from '@fortawesome/pro-light-svg-icons/faIdCardAlt';
import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faHandHeart } from '@fortawesome/pro-light-svg-icons/faHandHeart';
import { faPalette } from '@fortawesome/pro-light-svg-icons/faPalette';
import { faPresentation } from '@fortawesome/pro-light-svg-icons/faPresentation';
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons/faProjectDiagram';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faUserCrown } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';
import BadgeSVG from 'svg/badges.svg';

export const BADGE_TEMPLATE_ACTIVITY_TYPES = [
  'Application', 'Assessment', 'Award', 'Badge', 'Course', 'Credential', 'Education Experience', 'Leadership', 'Member', 'Other', 'Participant', 'Payment', 'Portfolio', 'Presenter', 'Project', 'Professional Experience', 'Schedule / Registration', 'Volunteer'
];

const ICONS = {
  "Application": faFileContract,
  "Assessment": faClipboardCheck,
  "Award": faTrophy,
  "Badge": BadgeSVG,
  "Course": faBook,
  "Credential": faIdCard,
  "Education Experience": faGraduationCap,
  "Leadership": faUserCrown,
  "Member": faUserTie,
  "Other": faEllipsisHAlt,
  "Participant": faIdCardAlt,
  "Payment": faCreditCard,
  "Portfolio": faPalette,
  "Presenter": faPresentation,
  "Project": faProjectDiagram,
  "Professional Experience": faBriefcase,
  "Schedule / Registration": faCalendarAlt,
  "Volunteer": faHandHeart
};

export const iconFor = (activityType) => {
  if (activityType in ICONS) {
    return ICONS[activityType];
  } else {
    // Log an error in development mode?
    return ICONS['Other'];
  }
};

import { intlKeyFromValue } from "translations";
import { findPrimaryIssuer } from "./selectors";
import { isEmpty } from "utils/object";

/**
 * Builds alt text for a badge template image
 *
 * @param {object} intl - ReactIntl instance
 * @param {object} badgeTemplate - badge template object
 *   @property {string} badgeTemplate.name - the name of the badge template
 *   @property {string} [badgeTemplate.type_category] - the type of the badge template
 *   @property {string} [badgeTemplate.level] - the level of the badge template
 * @property {object} issuer - the context in which the badge template is presented
*   @property {array} [issuer.entities] - the entities of the issuer
*   @property {string} [issuer.name] - the name of the issuer
 * @returns {string} alt text for a badge template image
 */
export const buildBadgeAltText = (intl, badgeTemplate, issuer) => {
  const { name, type_category: typeCategory, level } = badgeTemplate;

  const altText = [
    intl.formatMessage(
      {
        id: "badge.view.badge_image_alt_text_name",
        defaultMessage: "{badgeName} badge image"
      },
      { badgeName: name }
    )
  ];

  if (typeCategory) {
    altText.push(
      intl.formatMessage({
        id: intlKeyFromValue(typeCategory, "badge.type"),
        defaultMessage: typeCategory
      })
    );
  }

  if (level) {
    const intlLevel = intl.formatMessage({
      id: intlKeyFromValue(level, "badge.level"),
      defaultMessage: level
    });

    altText.push(
      intl.formatMessage(
        {
          id: "badge.view.badge_image_alt_text_level",
          defaultMessage: "{badgeLevel} level"
        },
        { badgeLevel: intlLevel }
      )
    );
  }

  const issuerName = !isEmpty(issuer?.entities) ? findPrimaryIssuer(issuer).name : issuer?.name;

  if (issuerName) {
    altText.push(
      intl.formatMessage(
        {
          id: "badge.view.issued_by_with_issuer",
          defaultMessage: "Issued by {issuer}"
        },
        { issuer: issuerName }
      )
    );
  }

  return altText.join(". ");
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './plain_text_evidence.sass';

/**
 * Render a PlainTextEvidence item, which consists of a title and description.
 *
 * @property {string} description - the description of the evidence
 * @propety {string} title - the title of the evidence
 */
const PlainTextEvidence = memo(({ title, description }) => {
  return (
    <div className="cr-badges-plain-text-evidence">
      <div className="cr-badges-plain-text-evidence__title">{title}</div>
      <div className="cr-badges-plain-text-evidence__desc">{description}</div>
    </div>
  );
});

PlainTextEvidence.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

PlainTextEvidence.displayName = 'PlainTextEvidence';
export { PlainTextEvidence };

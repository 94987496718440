import React from 'react';
import PropTypes from 'prop-types';
import Shiitake from 'shiitake';
import { makeClassName } from 'utils';
import { browserFeatures } from 'utils/browser';
import { SuspenseDisplayFix } from 'controls/suspense_display_fix';
import './clamp_lines.sass';

/**
 * Wrapper around the Shiitake component, designed to fix a React bug. See SuspenseDisplayFix.
 * This component can also crop text using CSS if supported by the browser, with the cssClamp
 * property.
 *
 * @param {Object} props
 *   @param {int} props.lines - The number of lines to clamp to.
 *   @param {int} [props.throttleRate=200] - Throttle ms for resize events.
 *   @param {String} [props.tagName='div'] - The HTML tag name. One of:
 *     {div, p, h1, h2, h3, h4, h5, h6, span, a}
 *   @param {Node} props.overflowNode - Show this instead of ellipses.
 *   @param {function(isTruncated: boolean)} props.onTruncationChange - Called whenever text becomes
 *     truncated, or stops being truncated.
 *   @param {Object} props.attributes - HTML attributes to apply to the top-level element.
 *   @param {{lineHeight: int}} props.cssClamp - If this exists and lineHeight is set, clamp lines
 *     using CSS when supported. This is not compatible with any properties other than `lines` and
 *     `attributes`.
 *     @param {int} props.cssClamp.lineHeight - The height of a single line of text.
 *
 * @see https://github.com/bsidelinger912/shiitake
 */
export const ClampLines = props => {
  const propsAttrs = props.attributes || {};

  // Because Shiitake does not work well with long single words, even with the css word-break
  // property, use css multiline clamping when supported. It's also much more performant, and
  // doesn't require the extra render of SuspenseDisplayFix.
  // @see https://github.com/bsidelinger912/shiitake/issues/14
  if (props.cssClamp && props.cssClamp.lineHeight && browserFeatures.lineClamp()) {
    return <div
      {...propsAttrs}
      className={makeClassName(propsAttrs.className, `clamp-lines__css-clamp`)}
      style={{
        maxHeight: props.cssClamp.lineHeight * props.lines,
        WebkitLineClamp: props.lines
      }}
    >
      {props.children}
    </div>;
  }

  const attributes = {
    ...props.attributes,
    ...React.useMemo(() => {
      const attr = props.attributes;
      // Shiitake doesn't automatically call onClick on space/enter, like many browser controls do.
      // Create that behavior here.
      if (attr && attr.onClick && !attr.onKeyUp && !attr.onKeyDown) {
        const cancelIfUsed = e => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            return true;
          }
          return false;
        };

        return {
          onKeyUp: e => cancelIfUsed(e) && props.attributes.onClick(e),
          onKeyDown: cancelIfUsed
        };
      }
      return {};
    }, [propsAttrs.onClick, !propsAttrs.onKeyUp, !propsAttrs.onKeyDown])
  };

  return <SuspenseDisplayFix><Shiitake {...props} attributes={attributes}/></SuspenseDisplayFix>;
};

ClampLines.propTypes = {
  cssClamp: PropTypes.shape({
    lineHeight: PropTypes.number.isRequired
  }),
  // Inherited from Shiitake
  lines: PropTypes.number.isRequired,
  throttleRate: PropTypes.number,
  tagName: PropTypes.string,
  overflowNode: PropTypes.node,
  onTruncationChange: PropTypes.func,
  attributes: PropTypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import { ClampLines } from 'controls/clamp_lines';
import { makeClassName } from 'utils';
import { SizedImage } from 'controls/sized_image';

import './standard_grid_item_content.sass';

export function StandardGridItemContent(props) {
  let alt = props.title;
  // If the props include "alt", use that, even if it's a falsy value; this allows us to override
  // default alt text with an empty value in case this is being used in a link or similar, where
  // the name of the badge is redundant with the name in the image alt text
  if (props.hasOwnProperty('alt')){
    alt = props.alt;
  }
  return (
    <div className={makeClassName('cr-standard-grid-item-content', props.className)}>
      <SizedImage
        className="cr-standard-grid-item-content__image"
        src={props.image_url}
        width={110}
        alt={alt}
      />
      <div className="cr-standard-grid-item-content__details">
        <ClampLines
          lines={4}
          cssClamp={{lineHeight: 26}}
          attributes={{className: 'cr-standard-grid-item-content__title'}}
        >
          {props.title}
        </ClampLines>
        {
          props.subtitle &&
          <ClampLines
            lines={2}
            cssClamp={{lineHeight: 19}}
            attributes={{className: 'cr-standard-grid-item-content__subtitle'}}
          >
            {props.subtitle}
          </ClampLines>
        }
      </div>
    </div>
  );
}

StandardGridItemContent.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image_url: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { BadgeOrTemplateTrackLink } from 'badges/badge_or_template_track_link';
import { SmartLink } from 'controls/smart_link';
import { Heading } from 'controls/heading';
import { FormattedMessage } from 'react-intl';

import './badge_standards.sass';

/**
 * Render any standards (called Alignment internally and in OBI) associated with a badge template.
 * Includes the heading "Standards", if there any standards are rendered, otherwise renders empty
 * when empty list of standards is provided.
 *
 * @property {Array<object>} standards - the list of standards, in the form they are emitted by the
 *   standard APIs
 * @property {object} tracking - the base tracking params to use when recording stats for clicks on
 *   any links that may be associated with the standards
 * @property {boolean} showHeading=true - whether or not to render the header
 */
export const BadgeStandards = ({ tracking, standards, showHeading }) => {
  /**
   * Render a single standard/Alignment object.
   *
   * @param {object} standard - the standard to render
   * @returns {JSX.Element}
   */
  const renderStandard = standard => (
    <div className="cr-badges-badge-standards__std" key={standard.id}>
      {renderStandardLink(standard)}
      {
        standard.description && (
          <div className="cr-badges-badge-standards__std-desc">
            {standard.description}
          </div>
        )
      }
    </div>
  );

  /**
   * Render a link to the URL provided in the standard, with appropriate tracking parameters.
   *
   * @param {object} standard - the standard for which to render the link
   *  @param {string} url
   *  @param {string} name
   * @returns {JSX.Element}
   */
  const renderStandardLink = ({ url, name }) => {
    const className = 'cr-badges-badge-standards__std-name';

    if (!tracking) {
      return (
        <SmartLink
          className={className}
          action={url}
          target="_blank"
        >
          {name}
        </SmartLink>
      );
    }

    return (
      <BadgeOrTemplateTrackLink
        className={className}
        to={url}
        target="_blank"
        track={{
          ...tracking,
          typeSuffix: 'standards.clicked',
          url,
          name
        }}
      >
        {name}
      </BadgeOrTemplateTrackLink>
    );
  };

  if (!standards || standards.length === 0) { return null; }

  return (
    <div className="cr-badges-badge-standards">
      {showHeading &&
        <Heading appearance="settings-head">
          <FormattedMessage
            id="badge.view.standards"
            defaultMessage="Standards"
          />
        </Heading>
      }
      <div className="cr-badges-badge-standards__stds">
        {standards.map(std => renderStandard(std))}
      </div>
    </div>
  );
};

BadgeStandards.propTypes = {
  standards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  tracking: PropTypes.object,
  showHeading: PropTypes.bool
};

BadgeStandards.defaultProps = {
  showHeading: true
};

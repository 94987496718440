import { useQuery } from '@tanstack/react-query';
import { getLearningOrgsFromPermissions } from '../api/learning_orgs_from_permissions';

export const useLearningOrgsFromPermissions = (userId, permission, isBadgeRecommendable) => {
  return useQuery({
    queryKey: ["permissionOrganizations", "list", permission, userId],
    queryFn: () => getLearningOrgsFromPermissions(userId, permission),
    staleTime: 1000 * 60 * 5,
    enabled: isBadgeRecommendable
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { RoleButton } from 'aria';
import { makeClassName } from 'utils';
import { workforceUrl } from 'utils/routes';
import {
  findMembership,
  getProfileAction,
  hasMappingUploadAccess,
  hasWorkforceAccess,
  membershipHasWorkforceRole
} from 'profile';
import { OrganizationManagementRouteHelpers } from 'management/organizations/route_helpers';

import './organization_selector_item.sass';

/**
 * Wrapper component consumed by `OrganizationSelectorCard` and `OrganizationSelectorRow`.
 * Encapsulates some shared logic such as rendering an outer Rolebutton that may either be a link
 * (when the selected item is not current) or just a regular div.
 *
 * @property {string} className - Custom classname to handle properties such as spacing margins
 * from the parent container
 * @property {boolean} current - Whether or not this particular component is rendering information
 * about the current organization that user is on in org management.
 * @property {OrganizationBackboneModel} organization - The organization that the component is displaying information
 *   for
 * @property {string} url - The url to navigate to when the user clicks on the card
 */
export const OrganizationSelectorItem = (props) => {
  const orgMgmtUrl =
    OrganizationManagementRouteHelpers.root.parameterize(
      { organization_id: props.organization.id }
    );
  const location = useLocation();
  const [currentUserState] = getProfileAction.useAction();
  const membership = findMembership(currentUserState.resources, props.organization.id);
  const passedUrl = props.url;
  const preferWorkforce = location.pathname.startsWith(workforceUrl.root()) ||
    (membership && membershipHasWorkforceRole(membership));

  const url =
    preferWorkforce &&
    (hasWorkforceAccess(currentUserState.resources, props.organization.id) ||
      hasMappingUploadAccess(currentUserState.resources, props.organization.id))
      ? workforceUrl.organization(props.organization.id)
      : orgMgmtUrl;
  const finalUrl = passedUrl || url;
  const className = makeClassName(
    'c-organization-selector-item',
    props.className
  );
  if (props.current) {
    return (
      <div className={className}>
        {props.children}
      </div>
    );
  } else {
    return (
      <RoleButton
        className={className}
        onClick={() => null}
        tagName={Link}
        tagProps={{ to: finalUrl }}
      >
        {props.children}
      </RoleButton>
    );
  }
};

OrganizationSelectorItem.propTypes = {
  className: PropTypes.string,
  current: PropTypes.bool,
  children: PropTypes.node,
  organization: PropTypes.object.isRequired,
  url: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

/**
 * HOC that adds an optional modifier className to the given component.
 * @example
 * // with "size" as the modifier, allowing values small, medium and large
 * const NewComponent = withModifierClassNames(
 *  MyComponent,
 *  'my-class',
 *  { size: ['small', 'medium', 'large'] }
 * )
 * // now doing this:
 * <NewComponent size="large" />
 * // will append `my-class--size-large` modifier className to given component.
 *
 * // Note: AllowedValues are used for prop validation, but won't prevent you from
 * // using an invalid value:
 * <NewComponent size="invalid" />
 * // will still produce a className like `my-class--size-invalid`, but will give a warning
 * // in props validation.
 *
 * @param {*} cpnt The component being extended with new modifier prop
 * @param {String} baseClassName The base className being used to build modifer name
 * @param {Object} modifiers
 *  @param {Array} modifiers.name0 List of allowed string values by modifier name
 * @returns {Function} new component with modifier className behavior
 */

export const withModifierClassNames = (cpnt, baseClassName, modifiers) => {
  const keys = Object.keys(modifiers);

  const newCpnt = (props) => {
    let className = props.className;

    keys.map((modifierName) => {
      className = makeClassName(
        props[modifierName] && `${baseClassName}--${modifierName}-${props[modifierName]}`,
        className
      );
    });

    const Cpnt = cpnt;
    return <Cpnt {...{ ...props, className }} />;
  };

  newCpnt.propTypes = {
    ...cpnt.propTypes
  };

  newCpnt.displayName = cpnt.displayName;

  if (typeof newCpnt.propTypes === 'object') {
    keys.map((modifierName) => {
      newCpnt.propTypes[modifierName] = modifiers[modifierName].length > 1
        ? PropTypes.oneOf(modifiers[modifierName])
        : PropTypes.string;
    });
  }

  return newCpnt;
};

import * as Routes from 'utils/routes';
import { defineRoutes } from 'app_utils/route_builder';

export const OrganizationManagementRouteHelpers = defineRoutes(
  `${Routes.organizationManagementUrl.root()}/organizations/:organization_id`,
  {
    issuerDashboard: '/dashboard',
    analytics: '/analytics',
    badges: '/badges',
    developers: '/devtools',
    account: '/account',
    employers: '/employers',
    earnerDirectory: '/earner_directory'
  }
);

export const AnalyticsRouteHelpers = defineRoutes(
  OrganizationManagementRouteHelpers.analytics,
  {
    badges: '/badges',
    templates: '/templates',
    transcripts: '/transcripts'
  }
);

export const BadgesRouteHelpers = defineRoutes(
  OrganizationManagementRouteHelpers.badges,
  {
    // Earners in sub-section routes
    earners: '/earners',
    showBadge: '/earners/:id',
    showBadgeDetails: '/earners/:id/details',
    showBadgeHistory: '/earners/:id/activity_log',
    showBadgeOverview: '/earners/:id/overview',
    showBadgeReplacementChain: '/earners/:id/replacement_chain',
    showDeleteBadge: '/earners/:id/delete',
    showReplaceBadge: '/earners/:id/replace',
    showRevokeBadge: '/earners/:id/revoke',
    // Collections sub-section routes
    collections: '/collections',
    addNewCollection: '/collections/new',
    deleteCollection: '/collections/:id/delete',
    showCollection: '/collections/:id',
    showCollectionDetails: '/collections/:id/details',
    showCollectionHistory: '/collections/:id/history',

    recommendations: '/recommendations',
    addNewRecommendation: '/recommendations/new',
    showRecommendation: '/recommendations/:id',
    deleteRecommendation: '/recommendations/:id/delete',
    showRecommendationSettings: '/recommendations/:id/settings',
    showRecommendationEditSettings: '/recommendations/:id/edit_settings',
    showRecommendationTemplates: '/recommendations/:id/templates',
    showRecommendationAnalytics: '/recommendations/:id/analytics',
    editRecommendationEditTemplates: '/recommendations/:id/edit_templates',
    editRecommendationAddTemplates: '/recommendations/:id/add_templates',
    downloadRecommendationExport: '/recommendation_exports/:id/download',

    // Templates sub-section routes
    templates: '/templates',
    addNewTemplate: '/templates/new',
    archivedTemplates: '/templates/archived',
    editTemplate: '/templates/:id/edit',
    archiveTemplate: '/templates/:id/archive',
    deleteTemplate: '/templates/:id/delete',
    editTemplateInsightsSettings: '/templates/:id/insights/settings',
    publishTemplate: '/templates/:id/publish',
    showTemplate: '/templates/:id',
    showTemplateDetails: '/templates/:id/details',
    showTemplateHistory: '/templates/:id/history',
    showTemplateInsights: '/templates/:id/insights',
    showTemplateIssueBadge: '/templates/:id/issue',
    showTemplateSettings: '/templates/:id/settings',

    templateEndorsements: '/templates/:badge_template_id/endorsements',
    addTemplateEndorsement: '/templates/:badge_template_id/endorsements/new',
    deleteTemplateEndorsement: '/templates/:badge_template_id/endorsements/:id/delete',
    editTemplateEndorsement: '/templates/:badge_template_id/endorsements/:id/edit',

    // AI credential routes
    aiCredential: '/ai_credential_suggestion',
    addNewAICredential: '/ai_credential_suggestion/new',

    // Bulk issue sub-section routes
    issue: '/issue',
    showBulkIssueSettings: '/issue/:id',

    // Pathways routes
    pathways: '/pathways',
    addNewPathway: '/pathways/new',
    showPathway: '/pathways/:pathway_id'
  }
);

export const DevelopersRouteHelpers = defineRoutes(
  OrganizationManagementRouteHelpers.developers,
  {
    // Authorization Tokens sub-section routes
    authorizationTokens: '/authorization_tokens',
    deleteAuthorizationToken: '/authorization_tokens/:id/delete',
    editAuthorizationToken: '/authorization_tokens/:id/edit',
    showAuthorizationToken: '/authorization_tokens/:id',

    // LTI Tools
    ltiTools: '/lti_tools',

    // Bridge API Keys sub-section routes
    bridgeKeys: '/lti_tools/bridge_keys',
    addNewBridgeKey: '/lti_tools/bridge_keys/new',
    deleteBridgeKey: '/lti_tools/bridge_keys/:id/delete',
    editBridgeKey: '/lti_tools/bridge_keys/:id/edit',
    showBridgeKey: '/lti_tools/bridge_keys/:id',

    // LTI Keys sub-section routes
    ltiKeys: '/lti_tools/lti_keys',
    deleteLtiKey: '/lti_tools/lti_keys/:id/delete',
    editLtiKey: '/lti_tools/lti_keys/:id/edit',
    showLtiKey: '/lti_tools/lti_keys/:id',

    // Integration Access Tokens sub-section routes
    integrationAccessTokens: '/lti_tools/integration_access_tokens',
    deleteIntegrationAccessToken: '/lti_tools/integration_access_tokens/:id/delete',
    showIntegrationAccessToken: '/lti_tools/integration_access_tokens/:id',

    // LTI Configurations sub-section routes
    ltiConfigurations: '/lti_configurations',

    // Template IDs sub-section routes
    templateIds: '/template_ids',

    // Webhooks configuration sub-section routes
    webhooks: '/webhooks',

    // SAML Settings sub-section routes
    samlSettings: '/saml_settings',
    addNewSamlSetting: '/saml_settings/new',
    showSamlSetting: '/saml_settings/:id',
    editSamlSetting: '/saml_settings/:id/edit',
    deleteSamlSetting: '/saml_settings/:id/delete',

    // Integrations sub-section routes
    integrations: '/integrations',
    newIntegration: '/integrations/:id/new',
    showIntegration: '/integrations/:id',
    editIntegration: '/integrations/:id/edit'
  }
);

export const PreferencesRouteHelpers = defineRoutes(
  OrganizationManagementRouteHelpers.account,
  {
    editPrint: '/print_options',

    // Print configuration sub-section routes
    editPrintOptions: '/print',

    // Edit Profile sub-section routes
    editProfile: '/profile',

    // Authorized organizations sub-section routes
    organizations: '/organizations',
    addNewOrganization: '/organizations/new',
    deleteOrganization: '/organizations/:id/delete',
    editOrganizationSettings: '/organizations/:id/edit',
    showOrganization: '/organizations/:id',
    showOrganizationCollections: '/organizations/:id/collections',
    showOrganizationHistory: '/organizations/:id/history',
    showOrganizationHistoryDetail: '/organizations/:issuer_authorization_id/history/:id',
    showOrganizationSettings: '/organizations/:id/settings',
    showOrganizationTemplates: '/organizations/:id/templates',
    editOrganizationEditTemplates: '/organizations/:id/edit_templates',
    editOrganizationAddTemplates: '/organizations/:id/add_templates',

    // Subscription data and management (for PMs) sub-section routes
    subscriptions: '/subscriptions',
    addNewSubscription: '/subscriptions/new',
    changeSubscriptionType: '/subscriptions/subscription_type',
    allSubscriptions: '/subscriptions/all',
    currentSubscription: '/subscriptions/current',
    deleteSubscription: '/subscriptions/all/:id/delete',
    showSubscription: '/subscriptions/all/:id',
    showSubscriptionDetails: '/subscriptions/all/:id/details',
    editSubscription: '/subscriptions/all/:id/edit',
    editSubscriptionType: '/subscriptions/all/:id/subscription_type',

    // Transcript Key sub-section routes
    transcriptKey: '/transcript_key',
    deleteTranscriptKey: '/transcript_key/delete',

    // User and invitation management sub-section routes
    users: '/users',
    editUser: '/users/active/:id/edit',
    newUserInvitation: '/users/invitations/new',
    newUserInvitationStandard: '/users/invitations/new/standard',
    newUserInvitationWorkforce: '/users/invitations/new/workforce'
  }
);

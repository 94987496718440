import React from 'react';
import PropTypes from 'prop-types';
import {makeClassName} from 'utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './icon.sass';


/**
 * Flexible icon renderer.
 *
 * @param {Object} props
 *   @param {Object|Function|String} props.image - A FontAwesome icon, SVG or image source.
 *   @param {String} props.className - A class name for the container.
 *   @param {String} props.imageClassName - A class name for the icon.
 * @returns {*}
 * @constructor
 */
const Icon = props => {
  const icon = props.image;
  if (!icon) {
    return '';
  }

  let content;
  const imageClassName = makeClassName('ac-icon__image', props.imageClassName);
  const commonProps = { className: imageClassName };
  if (props.title) {
    commonProps.title = props.title;
  }

  if (props.alt) {
    commonProps.alt = props.alt;
  }

  // FontAwesome icon
  if (icon.prefix && icon.iconName) {
    content = <FontAwesomeIcon icon={icon} {...commonProps} />;
  }
  else if (typeof icon == 'function') {
    const Icon = icon; // React components must start with a capital letter.
    content = <Icon {...commonProps} />;
  }
  else if (typeof icon == 'string') {
    content = <img src={icon} {...commonProps} />;
  }

  return <div className={makeClassName('ac-icon', props.className)}>{content}</div>;
};
export default Icon;


Icon.propTypes = {
  image: PropTypes.oneOfType([
    // FontAwesome
    PropTypes.shape({prefix: PropTypes.string.isRequired, iconName: PropTypes.string.isRequired}),
    // React component
    PropTypes.func,
    // Image source
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string
};

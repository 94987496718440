import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag, TagIcon, TagList } from 'controls/tags';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import * as SimpleSet from 'utils/set';

import './multi_select_tags.sass';

const tagHtmlId = (id) => id ? `tag-select-${id}` : null;

/**
 * UI component for selecting any number of tags from a predetermined list.
 *
 * Can be passed props from `useMultiSelectTags` for easy state management.
 *
 * @param {object} props
 * @param {function} props.deselectTag - Called when a tag is removed from the selection.
 * @param {function} props.selectTag - Called when a tag is added from the selection.
 * @param {object[]} props.tags - List of tags to be displayed.
 * @param {string} props.tags[].id - Unique identifier for the tag.
 * @param {string} props.tags[].name - Name to be displayed for the tag.
 * @param {boolean} props.tags[].isSelected - If the tag is currently selected.
 * @param {boolean} props.tags[].isDisabled - If the tag is disabled.
 * @param {string=} props.ui - Passed through to the Tag components.
 * @returns {JSX.Element}
 * @constructor
 */
export const MultiSelectTags = (props) => {
  const [focusedTagId, setFocusedTagId] = useState();
  const [usedKeyboardNav, setUsedKeyboardNav] = useState(false);

  const onFocus = useCallback((_event) => {
    if (!focusedTagId) {
      const firstSelected = props.tags.find((tag) => tag.isSelected);
      if (firstSelected) {
        setFocusedTagId(firstSelected.id);
      } else {
        const first = props.tags[0];
        if (first) { setFocusedTagId(first.id); }
      }
    }
  }, [focusedTagId, setFocusedTagId, props.tags]);

  const onBlur = useCallback((_event) => { setFocusedTagId(null); }, [setFocusedTagId]);

  const toggleTag = useCallback((tagId) => {
    const tag = props.tags.find((tag) => tag.id === tagId);
    !tag.isDisabled && setFocusedTagId(tagId);
    if (tag.isSelected) {
      props.deselectTag(tagId);
    } else {
      !tag.isDisabled && props.selectTag(tagId);
    }
  }, [setFocusedTagId, props.tags, props.deselectTag, props.selectTag]);

  const changeFocus = useCallback((event, move) => {
    const idx = props.tags.findIndex(tag => focusedTagId && tag.id === focusedTagId);
    if (idx !== -1) {
      let newIdx = idx + move;
      if (newIdx >= 0) {
        while (props.tags[newIdx]?.isDisabled && newIdx < props.tags.length) {
          // Keyboard interaction should bring us to the first NON disabled one
          newIdx += move;
        }
        if (newIdx >= props.tags.length) {
          // There was no tag to select :(
          return;
        }
        setFocusedTagId(props.tags[newIdx].id);
        event.preventDefault();
      }
    }
  }, [focusedTagId, setFocusedTagId, props.tags]);

  const handleKeyPress = useCallback((event) => {
    if (event.isComposing || event.keyCode === 229) {
      return;
    }

    setUsedKeyboardNav(true);

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        changeFocus(event, -1);
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        changeFocus(event, 1);
        break;
      case ' ':
        if (focusedTagId) {
          toggleTag(focusedTagId);
          event.preventDefault();
        }
        break;
    }
  }, [focusedTagId, changeFocus, toggleTag]);

  const onTagClick = useCallback((event) => {
    return toggleTag(event.currentTarget.dataset.tagid);
  }, [toggleTag]);

  return (
    <div
      role="listbox"
      aria-label={props['aria-label']}
      aria-multiselectable
      aria-activedescendant={tagHtmlId(focusedTagId)}
      tabIndex="0"
      onKeyDown={handleKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {props.showSelectedCount && <p className="multi_select_tags--selected-count">
        {props.tags.filter(tag => tag.isSelected).length} / {props.maxSelections}
      </p>}

      <TagList>
        {props.tags.map(tag => {
          const { isSelected } = tag;

          const hasFocus = focusedTagId && tag.id === focusedTagId;

          return (
            <div
              role="option"
              aria-selected={isSelected}
              onClick={onTagClick}
              id={tagHtmlId(tag)}
              data-tagid={tag.id}
              key={tag.id}
            >
              <Tag
                clickable={!tag.isDisabled}
                focused={usedKeyboardNav && hasFocus}
                highlighted={isSelected}
                ui={props.ui}
                icon={<TagIcon icon={isSelected ? faCheck : faPlus}/>}
              >
                {tag.name}
              </Tag>
            </div>
          );
        })}
      </TagList>
    </div>
  );
};

MultiSelectTags.propTypes = {
  'aria-label': PropTypes.string,
  selectTag: PropTypes.func.isRequired,
  deselectTag: PropTypes.func.isRequired,
  showSelectedCount: PropTypes.bool,
  maxSelections: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool
  })).isRequired,
  ui: PropTypes.oneOf(['default', 'skill', 'smallbold', 'standard'])
};

/**
 * @typedef {object} MultiSelectTagsHookProps
 * @property {function} selectTag - Callback when a tag is selected.
 * @property {function} deselectTag - Callback when a selected tag is deselected.
 * @property {string[]} selectedTagIds - IDs of the currently selected tags.
 * @property {object[]} tags - The original array of tag objects, augmented with
 *   the selection status of each tag.
 * @property {string} tags[].id - ID of the tag.
 * @property {boolean} tags[].isSelected - If the tag is currently selected.
 */

/**
 * Hook to simplify working with the data for `MultiSelectTags`.
 *
 * Takes an initial list of tags as input and returns a list of selected tags
 * as well as the props `MultiSelectTags` needs to update the data on user interactions.
 *
 * @param {object[]} tags
 * @param {string} tags.id - Unique ID for the tag.
 * @param {string} tags.name - Display name for the tag.
 * @returns {MultiSelectTagsHookProps}
 *
 * @example
 * const tagMultiHooks = useMultiSelectTags([
 *   { id: '1', name: 'Tag 1' },
 *   { id: '2', name: 'Tag 2' },
 *   { id: '3', name: 'Tag 3', isSelected: true },
 * ]);
 * console.log(tagMultiHooks.selectedTagIds)
 * return(
 *   <MultiSelectTags {...tagMultiHooks} />
 * );
 */
export const useMultiSelectTags = (tags, initalSelectedTagIds = [], opts = {}) => {
  const [selectedTagIds, setSelectedTagIds] = useState(initalSelectedTagIds);

  const selectTag = (tagId) => {
    setSelectedTagIds(ids => SimpleSet.add(ids, tagId));
  };
  const deselectTag = (tagId) => {
    setSelectedTagIds(ids => SimpleSet.remove(ids, tagId));
  };
  const augmentedTags = tags.map(tag => {
    const isSelected = SimpleSet.has(selectedTagIds, tag.id);
    // We need to disable any ones that would add to the list if we are maxed out
    let disabled = false;
    if (opts.maxSelections) {
      disabled = !isSelected && (selectedTagIds.length >= opts.maxSelections);
    }
    return {
      ...tag,
      isSelected: isSelected,
      isDisabled: disabled
    };
  });

  return { selectTag, deselectTag, selectedTagIds, tags: augmentedTags };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { BadgeRecommendation } from './badge_recommendation';
import { FormattedMessage } from 'react-intl';

import './badge_recommendations.sass';

/**
 * Renders the recommendations for a badge.
 *
 * @property {Array<object>} recommendations - an array of badge recommendations to render
 * @property {object} tracking - the base tracking parameters to use for any links rendered as
 *   part of the recommendations; {@see BadgeOrTemplateTrackLink} for shape of props
 * @property {boolean} suppressHeading=false - whether or not to render the header
 */
export const BadgeRecommendations = ({ recommendations, suppressHeading = false, tracking }) => {
  if (!recommendations || recommendations.length === 0) { return null; }

  return (
    <div className="cr-badges-recommendations">
      {!suppressHeading &&
        <Heading appearance="settings-head">
          <FormattedMessage
            id="badge.view.recommended"
            defaultMessage="Recommended"
          />
        </Heading>
      }
      <ul className="cr-badges-recommendations__recommendations">
        {recommendations.map(r => (
          <li className="cr-badges-recommendations__recommendation" key={r.id}>
            <BadgeRecommendation {...r} tracking={tracking}/>
          </li>
        ))}
      </ul>
    </div>
  );
};

BadgeRecommendations.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
  tracking: PropTypes.object,
  suppressHeading: PropTypes.bool
};

import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { EarningCriterion } from './earning_criterion';
import { FormattedMessage } from 'react-intl';

import './earning_criteria.sass';

/**
 * Renders the criteria (internally called activities) that an earner must have met to earn this
 * badge.
 *
 * @property {Array<object>} criteria - an array of badge criteria to render
 * @property {object} tracking - the base tracking parameters to use for any links rendered as
 *   part of the criteria; {@see BadgeOrTemplateTrackLink} for shape of props
 * @property {boolean} suppressHeading=false - whether or not to render the header
 */
export const EarningCriteria = ({ criteria, suppressHeading = false, tracking }) => {
  if (!criteria || criteria.length === 0) { return null; }

  return (
    <div className="cr-badges-earning-criteria">
      {!suppressHeading &&
        <Heading appearance="settings-head">
          <FormattedMessage
            id="badge.view.earning_criteria"
            defaultMessage="Earning Criteria"
          />
        </Heading>
      }
      <ul className="cr-badges-earning-criteria__criteria">
        {criteria.map(c => (
          <li className="cr-badges-earning-criteria__criterion" key={c.id}>
            <EarningCriterion {...c} tracking={tracking}/>
          </li>
        ))}
      </ul>
    </div>
  );
};

EarningCriteria.propTypes = {
  criteria: PropTypes.arrayOf(PropTypes.object).isRequired,
  tracking: PropTypes.object,
  suppressHeading: PropTypes.bool
};

/**
 * A simple immutable implementation of a set with unique membership.
 *
 * Uses arrays as the base type for ease of integration.
 * Maintains object identity when possible to help with React re-renders.
 * Performance for most operations should be assumed to be O(n).
 * Does not guarantee sort order.
 * @module SimpleSet
 */

/**
 * Add an item to the set.
 *
 * @param {*[]} set - The set to be modified.
 * @param {*} item - The item to be added.
 * @returns {*[]} - A new set with the item added. If the item was already a member
 *   of the original set, it will be returned unchanged.
 */
export const add = (set, item) => {
  if (has(set, item)) {
    return set;
  } else {
    return [...set, item];
  }
};

/**
 * Remove an item from the set.
 *
 * @param {*[]} set - The set to be modified.
 * @param {*} item - The item to be removed.
 * @returns {*[]} - A new set with the item removed. If the item was not a member
 *   of the original set, it will be returned unchanged.
 */
export const remove = (set, item) => {
  if (has(set, item)) {
    return set.filter((element) => element !== item);
  } else {
    return set;
  }
};

/**
 * Does an item exist in the set?
 *
 * @param {*[]} set - The set to be checked.
 * @param {*} item - The item search for.
 * @returns {boolean} - True if the item is a member of the set, false if not.
 */
export const has = (set, item) => set.includes(item);

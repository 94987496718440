import axios from "axios";

export const getCurrentUser = async () => {
  const { data } = await axios.get('/api/v1/users/self');

  if (data?.data) {
    return {
      ...data.data,
      metadata: data.metadata
    };
  } else {
    return null;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { BadgeIssuerPropType } from 'utils/prop_types';
import { BadgeOrTemplateTrackLink } from './badge_or_template_track_link';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { intlKeyFromValue } from "translations";
import { makeClassName } from 'utils';

import './badge_issuer.sass';

/**
 * Renders issuer/authorizer information for a badge or badge template.
 *
 * @property {object} issuer - issuer output, as from API output for badges/badge templates;
 *   {@see BadgeIssuerPropType} for expected shape of data
 * @property {object} [tracking] - if present, clicks on the issuer link (if shown) will be tracked
 *   using the provided properties for basic tracking parameters
 * @property {string} tracking.object_id - the ID of the object against which issuer is shown
 * @property {string} tracking.object_type - the type of object against which issuer is shown;
 *   typically this will be either "Badge" or "BadgeTemplate"
 * @property {object} [tracking.snapshot_json] - miscellaneous other data to send with stats
 */
export const BadgeIssuer = ({ tracking, issuer, appearance }) => {
  /**
   * Render a the name of a single issuer entity.  If the entity has a vanity_url, the name will be
   * rendered as a link to that URL, and if tracking prop is present, clicks of the link will result
   * in stats.
   *
   * @param entity - the entity for which to render the name
   * @returns {JSX.Element}
   */
  const renderEntityName = ({ vanity_url, id, name }) => {
    if (vanity_url) {
      const issuerUrl = vanity_url.replace(location.origin, '');

      if (tracking) {
        return (
          <BadgeOrTemplateTrackLink
            to={issuerUrl}
            track={{
              ...tracking,
              typeSuffix: 'issuer_name.clicked',
              issuer_id: id
            }}
          >
            {name}
          </BadgeOrTemplateTrackLink>
        );
      } else {
        return <Link to={issuerUrl}>{name}</Link>;
      }
    } else {
      return name;
    }
  };

  return (
    <div className="cr-badges-badge-issuer">
      {
        issuer.entities.map(data =>
          <div
            className={makeClassName("cr-badges-badge-issuer__entity",
              appearance && `cr-badges-badge-issuer__entity--${appearance}`)}
            key={data.entity.id}
          >
            <FormattedMessage
              id={intlKeyFromValue(data.label, "badge.view")}
              defaultMessage={data.label}
            />
            {' '}
            {renderEntityName(data.entity)}
          </div>
        )
      }
    </div>
  );
};

BadgeIssuer.propTypes = {
  appearance: PropTypes.oneOf([
    'min',
    'accept-badge-landing'
  ]),
  issuer: BadgeIssuerPropType.isRequired,
  tracking: PropTypes.object
};

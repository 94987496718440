import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { BadgeOrTemplateTrackLink } from './badge_or_template_track_link';
import { SmartLink } from 'controls/smart_link';

import './badge_recommendation.sass';

/**
 * Render an individual recommendation for a badge.
 *
 * @property {string} title - the title to display for the recommendation
 * @property {string} [url] - the URL of the recommendation (recommended_badge_template_url for all
 * badge recommendations and activity_url for all other types)
 */
export const BadgeRecommendation = memo(({
  title,
  tracking,
  recommended_badge_template_url,
  activity_url
}) => {
  const url = recommended_badge_template_url || activity_url;
  const renderRecommendationLink = () => {
    if (!tracking) {
      return (
        <SmartLink
          className="cr-badges-recommendation__title"
          action={url}
          target="_blank"
        >
          {title}
        </SmartLink>
      );
    }

    return (
      <BadgeOrTemplateTrackLink
        className="cr-badges-recommendation__title"
        to={url}
        target="_blank"
        track={{
          ...tracking,
          typeSuffix: 'recommendation.click',
          url,
          title
        }}
      >
        {title}
      </BadgeOrTemplateTrackLink>
    );
  };

  const classNames = [
    'cr-badges-recommendation',
    url && 'cr-badges-recommendation--actionable'
  ];

  return (
    <div className={makeClassName(classNames)}>
      {url ? renderRecommendationLink() : <div className="cr-badges-recommendation__title">{title}</div>}
    </div>
  );
});

BadgeRecommendation.displayName = 'Recommendation';

BadgeRecommendation.propTypes = {
  title: PropTypes.string.isRequired,
  tracking: PropTypes.object,
  recommended_badge_template_url: PropTypes.string,
  activity_url: PropTypes.string
};

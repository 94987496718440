import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import './tag_list.sass';

/**
 * A list of tags.
 *
 * Provides a container for basic layout. The tags are passed as children and rendered as-is.
 *
 * @param {object} props
 * @param {String} [props.className] - CSS class name to apply to the component.
 * @param {String} [props.align=left] - Alignment of the tags. Either "left" or "right".
 * @param {Boolean} [props.fullWidthMobile=false] - Tags should have 100% width on mobile.
 * @returns {JSX.Element}
 *
 * @example
 * <TagList>
 *   <Tag ui="skill">Debugging</Tag>
 *   <Tag ui="skill" highlighted>Documentation</Tag>
 *   <Tag ui="skill" highlighted>Examples</Tag>
 * </TagList>
 */
export const TagList = (props) => {
  const className = makeClassName(
    'cr-tag-list',
    props.align === 'right' && 'cr-tag-list--align-right',
    props.className,
    props.fullWidthMobile && `cr-tag-list__full-width-mobile`
  );

  return (
    <div className={className}>
      {props.children}
    </div>
  );
};

TagList.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidthMobile: PropTypes.bool
};

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { makeClassName } from 'utils';
import element from 'utils/element';
import { TagList, Tag, TAG_UIS } from 'controls/tags';
import { RoleButton } from 'aria/role_button';
import './truncated_tag_list.sass';

/**
 * A collection of tags that can be truncated with a clickable ellipsis when they get too long.
 *
 * NOTE: for performance reasons, it is preferable to pass in a value for props.children that
 * only changes when there is an actual change to the content of the tags rendered within this
 * component.
 *
 * @property {String} className - CSS class name to apply to the component.
 * @property {String} [ui=default] - The UI style. One of:
 *   - default: Default style.
 *   - skill: Skill tag display with green color scheme
 *   - skill-bold: Skill tag display with green color scheme and bold font for workforce screens,
 *                 with ellipses removed.
 *   - smallbold: Smaller font, bold text.
 *   - standard: New design system standard; will eventually replace default (one hopes)
 * @property {int} [maxRows] - The maximum number of wrapped rows to display, before cutting off
 *   and displaying an ellipses element.
 * @property {int} [maxRowsMobile=maxRows] - The maximum number of rows to display on mobile.
 *   Set to 0 to disable truncation on mobile.
 *   Set to -1 to inherit from `maxRows`, I guess?
 * @property {Boolean} fullWidthMobile - Tags should have 100% width on mobile. WARNING: buggy when used
 *   in combination with maxRowsMobile > 0. Not recommended.
 *   See: https://wfspearson.atlassian.net/browse/AC-8780
 * @property {Boolean} ellipsesActive - Use the "active" state for the injected ellipses element.
 * @property {Boolean} noEllipsesExpand - Don't expand the list when ellipses are clicked.
 * @property {Function} onEllipsesClick - Call when ellipses are clicked, for custom behavior.
 * @property {Boolean} suppressEllipses - Removes presence of ellipses and skips calculations.
 */
export const TruncatedTagList = (props) => {
  const [forceExpand, setForceExpand] = useState(false);
  const [injectEllipsesIdx, setInjectEllipsesIdx] = useState(-1);
  const [updatingEllipses, setUpdatingEllipses] = useState(false);
  const container = useRef();

  const maxRows = forceExpand ? 0 : props.maxRows;
  const maxRowsMobile = forceExpand ? 0 : (
    props.maxRowsMobile === -1 ? maxRows : props.maxRowsMobile
  );

  /**
   * When window is resized, recalculate whether and where ellipses should be shown.
   */
  useEffect(() => {
    const throttled = throttle(() => {
      setUpdatingEllipses(true);
    }, 500, { leading: true, trailing: true });
    window.addEventListener('resize', throttled);
    return () => {
      window.removeEventListener('resize', throttled);
    };
  }, [props.suppressEllipses]);

  /**
   * If key props change such that the tags may need to be truncated in a new position, trigger
   * a recalculation.
   */
  useEffect(() => {
    setUpdatingEllipses(true);
  }, [props.children, maxRows, maxRowsMobile]);

  /**
   * If we should recalculate whether/where to place the ellipses, do so.
   */
  useLayoutEffect(() => {
    if (updatingEllipses) {
      setInjectEllipsesIdx(
        element.lastChildInViewIdx(
          container.current, '.cr-tag', props.suppressEllipses ? 0 : TagEllipses.WIDTH
        )
      );
      setUpdatingEllipses(false);
    }
  }, [updatingEllipses, props.suppressEllipses]);

  /**
   * If the parent component is interested in being notified about whether some tags are not able
   * to be shown, notify them of such changes.
   */
  useEffect(() => {
    props.onTagsTruncatedChanged && props.onTagsTruncatedChanged(injectEllipsesIdx >= 0);
  }, [injectEllipsesIdx >= 0]);

  const onEllipsesClick = (evt) => {
    evt.preventDefault();
    props.onEllipsesClick && props.onEllipsesClick();
    if (!props.noEllipsesExpand) {
      setForceExpand(true);
    }
  };

  const className = makeClassName(
    'cr-truncated-tag-list',
    `cr-truncated-tag-list__rows-${maxRows}`,
    `cr-truncated-tag-list__rows-mobile-${maxRowsMobile}`,
    props.className,
    `cr-truncated-tag-list--${props.ui}`
  );
  const listProps = {
    className,
    align: props.align,
    fullWidthMobile: props.fullWidthMobile
  };

  return (
    <div ref={container} className="cr-truncated-tag-list__outer">
      <TagList {...listProps}>
        {React.Children.map(props.children, (child, idx) => {
          if (
            injectEllipsesIdx === -1 || idx <= injectEllipsesIdx || updatingEllipses || forceExpand
          ) {
            return child;
          }
        })}
        {injectEllipsesIdx > 0 && !props.suppressEllipses && !forceExpand && !updatingEllipses &&
          <TagEllipses
            ui={props.ui}
            className={`cr-tag--${props.ui}`}
            highlighted={props.ellipsesActive}
            onClick={onEllipsesClick}
          />
        }
      </TagList>
    </div>
  );
};

TruncatedTagList.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.any,
  className: PropTypes.string,
  ellipsesActive: PropTypes.bool,
  noEllipsesExpand: PropTypes.bool,
  onEllipsesClick: PropTypes.func,
  maxRows: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]).isRequired,
  maxRowsMobile: PropTypes.oneOf([-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
  fullWidthMobile: PropTypes.bool,
  ui: PropTypes.oneOf(TAG_UIS),
  suppressEllipses: PropTypes.bool,
  onTagsTruncatedChanged: PropTypes.func
};

TruncatedTagList.defaultProps = {
  align: 'left',
  ui: 'default',
  maxRowsMobile: -1,
  noEllipsesExpand: false,
  suppressEllipses: false
};

/**
 * If tags wrap out of view, an ellipses tag is injected into the list.
 *
 * @private
 */
const TagEllipses = props =>
  <RoleButton
    tagName="div"
    aria-label="Expand"
    onClick={props.onClick}
  >
    <Tag
      className={makeClassName(
        'cr-tag-ellipses',
        props.className
      )}
      highlighted={props.highlighted}
      clickable={!!props.onClick}
      ui={props.ui}
    >
      <span className="cr-tag-ellipses__dots">...</span>
    </Tag>
  </RoleButton>;

// Having a link between JS and CSS isn't a great idea, but it improves performance. Otherwise, we'd
// have to measure the ellipses tag at runtime.
TagEllipses.WIDTH = 61; // 51px width, plus 10px margins. See tag.sass.
TagEllipses.propTypes = {
  className: PropTypes.string,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func,
  ui: PropTypes.string
};

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LearningRouteHelpers } from 'pages/workforce/route_helpers';
import { FormattedMessage } from 'react-intl';
import { Button } from 'controls/button';
import {
  LearningOrganizationsSelector
} from 'management/organizations/sidebar/organization_modal_selector/learning_organizations_selector';
import { useLearningOrgsFromPermissions } from './hooks/use_learning_orgs_from_permissions';
import { useCurrentUser } from 'queries/hooks/use_current_user';

/**
 * Renders a button that allows the user to recommend a badge to an organization.
 * This button is only shown if the user has permission to create learning plans.
 *
 * @param {object} badgeTemplate - the badge template data, in the form returned by the public
 *   badge template endpoints, e.g. GET /org/:org_vanity_slug/badge/:badge_template
 * @param {string} className
 * @param permission
 * @returns {JSX.Element|null}
 */
const RecommendBadgeButton = ({ badgeTemplate, className, permission }) => {
  const { data: currentUser } = useCurrentUser();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const callAPI = badgeTemplate.recommendable && !!currentUser?.id;

  const {
    data: orgsFromPermissions,
    isLoading
  } = useLearningOrgsFromPermissions(currentUser?.id, permission, callAPI);

  const handleRecommendBadgeClick = () => {
    if (orgsFromPermissions?.data.length > 1) {
      setShowModal(true);
    } else {
      history.push(
        LearningRouteHelpers.new.parameterize({
          organization_id: orgsFromPermissions?.data[0].id,
          templateId: badgeTemplate.id
        })
      );
    }
  };

  if (!isLoading && orgsFromPermissions?.data?.length > 0) {
    return (
      <div className={className}>
        <Button
          type="secondary"
          id="recommend-badge-button"
          className="cr-badge-template-details__recommend"
          onClick={handleRecommendBadgeClick}
        >
          <FormattedMessage id="badge.view.recommend_this_badge" defaultMessage="Recommend This Badge" />
        </Button>
        <LearningOrganizationsSelector
          show={showModal}
          onClose={() => setShowModal(false)}
          organizations={orgsFromPermissions?.data}
          badgeTemplateId={badgeTemplate.id}
        />
      </div>
    );
  }
  return null;
};

RecommendBadgeButton.propTypes = {
  badgeTemplate: PropTypes.object.isRequired,
  className: PropTypes.string,
  permission: PropTypes.string
};

export default RecommendBadgeButton;

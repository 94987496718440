import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isDisplayableEndorsement } from './selectors';
import element from 'utils/element';
import { tracking as trackingUtil } from 'app_utils/tracking';
import { Heading, NewHeadingLevel } from 'controls/heading';
import { Endorsement } from './endorsement';

import './endorsements.sass';

/**
 * Renders a list of endorsements.  Delegates individual items to {@link Endorsement} component.
 *
 * @property {array<object>} endorsements - the raw list of Endorsement objects, transformed into
 *   a list of endorsement-like objects by flattening Credits to be at the same level as the
 *   Endorsement to which they are attached
 * @property {object} [tracking] - extra tracking parameters to send with click and view stats
 *   recorded for the endorsements, e.g. the ID of the badge or badge template that the Endorsements
 *   are being presented against
 */
export const Endorsements = ({ endorsements, tracking }) => {
  const intl = useIntl();
  const removeWhenFirstVisibleRef = useRef(null);

  useEffect(() => {
    /**
     * Makes a call to track an endorsement.view stat for each endorsement shown by this component.
     * Hooked up to whenFirstVisible so that views are recorded the first time the user scrolls the
     * endorsements into view.
     */
    const trackEndorsementViews = () => {
      endorsements.forEach(endorsement => {
        trackingUtil.track({
          ...tracking,
          type: 'endorsement.view',
          object_id: endorsement.id,
          object_type: 'Endorsements::Endorsement'
        });
      });
    };

    removeWhenFirstVisibleRef.current =
      element.whenFirstVisible('.cr-badges-endorsements', trackEndorsementViews);

    return () => {
      removeWhenFirstVisibleRef.current && removeWhenFirstVisibleRef.current();
    };
  }, [endorsements, tracking]);

  if (!endorsements || endorsements.length === 0) { return null; }

  return (
    <div className="cr-badges-endorsements">
      <Heading appearance="settings-head">
        {intl.formatMessage({ id: 'badge.view.endorsements', defaultMessage: 'Endorsements' })}
      </Heading>
      <NewHeadingLevel>
        <ul className="cr-badges-endorsements__endorsements">
          {endorsements.filter(isDisplayableEndorsement).map(endorsement => (
            <Endorsement
              {...endorsement}
              key={endorsement.id}
              className="cr-badges-endorsements__endorsement"
              tracking={tracking}
            />
          ))}
        </ul>
      </NewHeadingLevel>
    </div>
  );
};

Endorsements.propTypes = {
  endorsements: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      endorser: PropTypes.object.isRequired,
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      credits: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired
        })
      )
    })
  ),
  tracking: PropTypes.object
};

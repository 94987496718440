import React from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';

/**
 * An empty component that creates a div (or other) wrapper
 * with a default (base) class name. For components that are controlled
 * mainly by html and css. Should not be used for components needing special
 * functionality that cannot be added through props.
 * @param {Object} props
 *  @param {String} props.baseClassName base class name for component
 *  @param {*} props.tagName Component or html tag name to use as external wrapper
 * @returns {JsxElement}
 */

export const Box = ({ className, baseClassName, children, tagName, ...otherProps }) => {
  const TagName = tagName;
  return (
    <TagName
      {...otherProps}
      className={makeClassName(baseClassName, className)}
    >
      {children}
    </TagName>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  baseClassName: PropTypes.string.isRequired,
  tagName: PropTypes.any.isRequired
};

Box.defaultProps = {
  tagName: 'div'
};

/**
 * Creates component that assigns default value to baseClassName (and other props) in Box
 * component.
 *
 * @param {String} displayName  The new component's display name
 * @param {String} baseClassName  The default baseClassName given to returned component.
 * @param {Object} otherProps   Other default props given to returned component.
 * @returns {Function} Box functional component.
 */

export const makeBox = (displayName, baseClassName, otherProps = {}) => {
  const cpnt = (props) => {
    return (
      <Box
        {...otherProps}
        {...props}
        {...{ baseClassName }}
      />
    );
  };

  cpnt.displayName = displayName;
  return cpnt;
};

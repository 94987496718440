import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'controls/heading';
import { KeyValueGroupEvidence } from './key_value_group_evidence';
import { PlainTextEvidence } from './plain_text_evidence';
import { UrlEvidence } from './url_evidence';
import { IdEvidence } from './id_evidence';
import { useIntl } from "react-intl";

import './evidence.sass';

/**
 * Renders evidence associated with a badge.
 *
 * @property {Array<object>} evidence - an array of evidence items, with structure for each evidence
 *   type matching that produced by the badges API
 * @property {object} tracking - an object of base tracking data, in a form that {@link TrackLink}
 *   can accept for its track property
 * @property {string} tracking.object_id - the ID of the badge associated with this evidence
 * @property {string} tracking.object_type - "Badge"
 */
export const Evidence = ({ evidence, tracking }) => {
  const intl = useIntl();
  const evidenceByType = (type) => evidence.filter((e) => e.type === type);

  const renderKeyValueGroupEvidence = () => {
    const evidence = evidenceByType('KeyValueGroupEvidence');

    return evidence.map((e) => (
      <KeyValueGroupEvidence {...e} tracking={tracking} key={e.id} />
    ));
  };

  const renderPlainTextEvidence = () => {
    const evidence = evidenceByType('PlainTextEvidence');

    return evidence.map((e) => <PlainTextEvidence {...e} key={e.id} />);
  };

  const renderUrlEvidence = () => {
    const evidence = evidenceByType('UrlEvidence');

    return evidence.map((e) => (
      <UrlEvidence {...e} tracking={tracking} key={e.id} />
    ));
  };

  const renderIdEvidence = () => {
    const evidence = evidenceByType('IdEvidence');

    return evidence.map((e) => <IdEvidence {...e} key={e.id} />);
  };

  if (!evidence || evidence.length === 0) { return null; }

  return (
    <div className="cr-badges-evidence">
      <Heading appearance="settings-head">
        {intl.formatMessage({ id: 'badge.view.evidence', defaultMessage: 'Evidence' })}
      </Heading>
      <div className="cr-badges-evidence__all">
        {renderKeyValueGroupEvidence()}
        {renderPlainTextEvidence()}
        {renderUrlEvidence()}
        {renderIdEvidence()}
      </div>
    </div>
  );
};

Evidence.propTypes = {
  evidence: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ),
  tracking: PropTypes.object
};

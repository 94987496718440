/**
 * Validate various data types.
 */
export class Validators {
  /**
   * Is this a valid ISO8601-style date?
   *
   * @param {string} val
   * @returns {boolean}
   */
  static iso8601Date(val) {
    const dateMatch = /^(\d{1,4})-(\d{1,2})-(\d{1,2})$/.exec((val || '').trim());
    if (dateMatch) {
      const day = parseInt(dateMatch[3]);
      const month = parseInt(dateMatch[2]) - 1;
      const year = parseInt(dateMatch[1]);
      const asDate = new Date(Date.UTC(year, month, day));
      // JS will happily interpret month 14 as March of the following year, and so on, so this
      // checks that the date as provided is a valid calendar date
      return (
        asDate.getUTCFullYear() === year &&
        asDate.getUTCMonth() === month &&
        asDate.getUTCDate() === day
      );
    }
    return false;
  }

  /**
   * Is this a valid email address?
   *
   * @param {string} val
   * @returns {boolean}
   * @see https://emailregex.com/
   */
  static email(val) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      .test((val || '').trim());
  }


  /**
   * Is this a valid URL?
   *
   * @param {string} val
   * @returns {boolean}
   * @see https://mathiasbynens.be/demo/url-regex , entry from @gruber v2 (used for brevity)
   */
  static url(val) {
    return /((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}.|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/i
      .test((val || '').trim());
  }

  /**
   * Is this an integer?
   *
   * @param {string} val
   * @returns {boolean}
   */
  static integer(val) {
    return /^[1-9]\d*$|^0$/.test(val);
  }

  /**
   * Is this a number?
   *
   * @param {string} val
   * @returns {boolean}
   */
  static number(val) {
    return /^[1-9]\d*(\.\d+)?$|^0?\.\d+$|^0$/.test(val);
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import element from 'utils/element';

const POLL_INTERVALS = [50, 50, 100, 200];

/**
 * This component works around a known defficiency in React, for which there is currently no planned
 * fix (as of 12-2019).
 *
 * Here's what happens when a component is lazy loaded using React.lazy/React.Suspense:
 * - The JS is downloaded and parsed.
 * - The DOM parent it is given the "display: none" style.
 * - The children are fully rendered.
 * - The "display: none" style is removed.
 *
 * Any attempt to measure a DOM element's dimensions while "display: none" is on that child's parent
 * will result in a height and width of zero. Since React has no event that indicates when this
 * transition is complete, we have to poll for the style change, and defer render until the parent
 * is visible.
 *
 * An alternative to polling would be MutationObserver, but all parent nodes would have to be
 * observed, so performance might be worse.
 */
export class SuspenseDisplayFix extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.checkVisibilityTimer = null;
    this.pollIntervalIdx = 0;

    this.state = {
      renderable: false
    };
  }

  /**
   * The component just mounted. Start polling for display: none.
   */
  componentDidMount() {
    this.pollRenderable();
  }

  /**
   * The component is about to unmount.
   */
  componentWillUnmount() {
    this.checkVisibilityTimer && clearTimeout(this.checkVisibilityTimer);
  }

  /**
   * Poll for display: none
   */
  pollRenderable = () => {
    if (element.isDisplayed(this.ref.current)) {
      this.checkVisibilityTimer = null;
      this.setState({renderable: true});
    } else {
      // Ramp up poll intervals. It typically takes 50-100ms for the parent to become visible if
      // Suspense is the issue, but if the parent is hidden for some other reason, we don't want to
      // spend too much CPU power checking visibility.
      this.checkVisibilityTimer =
        setTimeout(this.pollRenderable, POLL_INTERVALS[this.pollIntervalIdx]);
      this.pollIntervalIdx = Math.min(this.pollIntervalIdx + 1, POLL_INTERVALS.length - 1);
    }
  };

  /**
   * Render the component.
   *
   * @returns {React.element}
   */
  render() {
    return this.state.renderable ? this.props.children : <span ref={this.ref}/>;
  }
}

export const testing = { POLL_INTERVALS };

SuspenseDisplayFix.propTypes = {
  children: PropTypes.any
};

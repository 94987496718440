import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from 'utils';
import { RoleButton } from 'aria';
import { Avatar } from 'controls/avatar';
import { ClampLines } from 'controls/clamp_lines';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { OrganizationSelectorItem } from './organization_selector_item';

import './organization_selector_card.sass';

/**
 * Renders a card visual to enable a user to either navigate to a different organization in org
 * management or to view the organization's profile.
 *
 * @property {string} className - Custom classname to handle properties such as spacing margins
 * from the parent container
 * @property {boolean} current - Whether or not this particular component is rendering information
 * about the current organization that user is on in org management.
 * @property {boolean} [fixedWidth] - whether to display the card at a fixed width of 146px, or to
 *   allow it to shrink on a narrower screen
 * @property {string} onClose - An optional callback function that when given will render a close
 * icon for a user to click and trigger the callback.
 * @property {object} organization - The organization that the component is displaying information
 *   for
 * @property {string} url - The url to navigate to when the user clicks on the card
 */
export const OrganizationSelectorCard = (props) => {
  const org = props.organization;

  const handleCloseIconClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onClose();
  }, [props.onClose]);

  const selectorCardClass = makeClassName(
    'c-organization-selector-card',
    props.current && 'c-organization-selector-card--current',
    props.fixedWidth && 'c-organization-selector-card--fixed-width',
    props.className
  );

  return (
    <OrganizationSelectorItem
      className={selectorCardClass}
      current={props.current}
      organization={props.organization}
      url={props.url}
    >
      {props.onClose &&
        <RoleButton
          className="c-organization-selector-card__close-icon"
          onClick={handleCloseIconClick}
          tagName="div"
        >
          <FontAwesomeIcon
            icon={faTimes}
          />
        </RoleButton>
      }
      <div className="c-organization-selector-card__org-photo-container">
        <Avatar
          src={org.photo_url}
          alt={org.name}
          width={48}
          emptyImage={
            <div className="c-organization-selector-card__org-photo--blank">
              <FontAwesomeIcon icon={faBuilding}/>
            </div>
          }
        />
      </div>
      <div className="c-organization-selector-card__org-name">
        <ClampLines lines={2} attributes={{ title: org.name }}>
          {org.name}
        </ClampLines>
      </div>
    </OrganizationSelectorItem>
  );
};

OrganizationSelectorCard.propTypes = {
  className: PropTypes.string,
  current: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  onClose: PropTypes.func,
  organization: PropTypes.object.isRequired,
  url: PropTypes.string
};

OrganizationSelectorCard.defaultProps = {
  fixedWidth: false
};

import React from 'react';
import { Placeholder } from 'controls/placeholder';
import { makeClassName } from 'utils';
import { withIntlProps } from 'translations/with_intl_props';
import { SizedImage } from 'controls/sized_image';
import PropTypes from 'prop-types';
import './visual.sass';

export const DEFAULT_VISUAL_WIDTH = 120;

export const VisualPlaceholder = ({ className, tagName, shape }) => {
  const TagName = tagName || Placeholder;
  return (
    <TagName
      className={makeClassName(
        'cr-visual-placeholder',
        className,
        shape && `cr-visual-placeholder--shape-${shape}`
      )}
    />
  );
};

/**
 * Visual component presents various image states including loading and empty.
 *
 * @param {Object} props
 *  @param {Boolean} props.loadng is the text loading
 *  @param {Object} loadingSettings
 *   @param {String} loadingSettings.className loader custom className
 *   @param {String} loadingSettings.shape the shape modifier, if empty defaults to `props.shape`
 *   @param {String} loadingSettings.tagName component being rendered by loader defaults to Placeholder
 *  @param {String} props.shape the shape modifier.
 *  @param {String} props.className custom class name
 *  @param {String} props.emptyValue the image src used when value is empty.
 *  @param {*} props.tagName tagName used to wrap visual element, defaults to 'div'
 *  @param {String} props.value the image src.
 *  @param {String} props.alt the alt tag
 *  @param {Number} props.height numeric min height of container, required to determine the size of the loader.
 *  @param {Number} props.width numeric min width of container. Defaults to 120
 * @returns {JsxElement}
 */

const Visual = ({
  loading,
  loadingSettings,
  height,
  width,
  className,
  tagName,
  emptyValue,
  value,
  alt,
  shape
}) => {
  const TagName = tagName || 'div';
  return (
    <TagName
      style={{
        minWidth: `${width}px`,
        minHeight: `${height}px`
      }}
      className={
        makeClassName(
          'cr-visual',
          className
        )
      }
    >
      {!loading && (
        <SizedImage
          className="cr-visual__image"
          src={value || emptyValue}
          width={width || DEFAULT_VISUAL_WIDTH}
          alt={alt}
        />
      ) || (
        <VisualPlaceholder
          {...{ shape }}
          {...loadingSettings}
        />
      )}
    </TagName>
  );
};

Visual.propTypes = {
  loading: PropTypes.bool,
  loadingSettings: PropTypes.shape({
    className: PropTypes.string,
    shape: PropTypes.oneOf(['circle', 'badge'])
  }),
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
  tagName: PropTypes.any,
  emptyValue: PropTypes.any,
  value: PropTypes.string,
  alt: PropTypes.string,
  shape: PropTypes.oneOf(['circle', 'badge'])
};

const VisualWithIntl = withIntlProps(Visual, ['alt']);

export { VisualWithIntl as Visual };
